import Vue from "vue";
import AxiosServices from "@/services/AxiosServices";
import store from "../store/store";
import VueRouter from "vue-router";
// Error
import NotFound from "../Error/NotFound.vue";
import NetworkIssue from "../Error/NetworkIssue.vue";
// Views
// import Keno from "../views/User/Keno.vue";
import Admin from "../views/Admin/Admin.vue";
import Login from "../components/Authentication/Login.vue";

import ForgotPassword from "../components/Authentication/ForgetPassword.vue";
import Cashier from "../views/Admin/Cashier.vue";
import CashierOptions from "../views/Admin/CashierOptions";
import Config from "../views/Admin/Config.vue";
import Dashboard from "../views/Admin/Dashboard.vue";
import ResetPassword from "@/components/Authentication/ResetPassword.vue";
import ResetPasswordByLink from "../components/Authentication/ResetPasswordByLink.vue";
import RecallHistory from "../views/Admin/RecallHistory.vue";
// import error from "@/store/modules/error";
const preventRoute = {
  beforeEnter: (to, from, next) => {
    if (localStorage.getItem("encryptedCashierToken")) {
      // AxiosServices.getMe()
      // .then((res) => {
      next();
      // })
      // .catch((error) => {
      //   localStorage.clear();
      //   const alert = {
      //     type: "error",
      //     message: "Invalid token Please login again",
      //   };
      //   store.dispatch("notification/add", alert);
      //   if (to.path == "/") {
      //     next();
      //   } else {
      //     next("/");
      //   }
      // });
      // next();
    } else {
      localStorage.clear();
      const alert = {
        type: "error",
        message: "Unauthorized Navigation",
      };
      store.dispatch("notification/add", alert);
      next("/");
    }
  },
};
const requireAuth = (to, from, next) => {
  const token = localStorage.getItem("encryptedCashierToken");
  if (token) {
    AxiosServices.getMe()
      .then((res) => {
        next("/cashier");
      })
      .catch((error) => {
        localStorage.clear();
        const alert = {
          type: "error",
          message: "Invalid token Please login again",
        };
        store.dispatch("notification/add", alert);
        if (to.path == "/") {
          next();
        } else {
          next("/");
        }
      });
  } else {
    if (to.path === "/") {
      // check if current route is the same as destination route
      next();
    } else {
      next("/");
    }
  }
};
// const preventRoute = {
//   beforeEnter: (to, from, next) => {
//     if (localStorage.getItem("encryptedCashierToken")) {
//       next();
//       AxiosServices.getMe()
//         .then((response) => {
//           //first back have to validate the token to let user pass
//           next("/Dashboard");
//         })
//         .catch((error) => {
//           localStorage.clear();
//           const alert = {
//             type: "error",
//             message: "Invalid token Please login!",
//           };
//           store.dispatch("notification/add", alert);
//           next("/");
//         });
//     } else {
//       localStorage.clear();
//       const alert = {
//         type: "error",
//         message: "Unauthorized Navigation",
//       };
//       store.dispatch("notification/add", alert);
//       next("/");
//     }
//   },
// };
// const requireAuth = (to, from, next) => {
//   const token = localStorage.getItem("encryptedCashierToken");
//   if (token) {
//     AxiosServices.getMe()
//       .then((response) => {
//         //first back have to validate the token to let user pass
//         next("/Dashboard");
//       })
//       .catch((error) => {
//         localStorage.clear();
//         const alert = {
//           type: "error",
//           message: "Invalid token Please login!",
//         };
//         store.dispatch("notification/add", alert);
//         next("/");
//       });
//   } else {
//     if (to.path === "/") {
//       // check if current route is the same as destination route
//       next();
//     } else {
//       next("/");
//     }
//   }
// };

Vue.use(VueRouter);

const routes = [
  {
    path: "/404",
    name: "404",
    component: NotFound,
    meta: {
      title: "NotFound",
    },
  },
  {
    path: "/network-issue",
    name: "network-issue",
    component: NetworkIssue,
    meta: {
      title: "NetworkIssue",
    },
  },
  {
    path: "*",
    redirect: { name: "404" },
  },
  {
    path: "/",
    name: "log-in",
    component: Login,
    beforeEnter: requireAuth,
    meta: {
      title: "Login",
    },
  },
  {
    path: "/forgotPassword",
    name: "forgot-password",
    component: ForgotPassword,
    meta: {
      title: "ForgotPassword",
    },
  },
  {
    path: "/ResetPassword",
    name: "reset-password",
    component: ResetPassword,
    meta: {
      title: "ResetPassword",
    },
  },
  {
    path: "/ResetPasswordByLink/:id",
    name: "reset-password-by-link",
    component: ResetPasswordByLink,
    meta: {
      title: "ResetPasswordByLink",
    },
  },
  {
    path: "/Admin",
    name: "admin",
    component: Admin,
    ...preventRoute,
    children: [
      {
        path: "/Dashboard",
        name: "dashboard",
        component: Dashboard,
        meta: {
          title: "Dashboard",
        },
        ...preventRoute,
      },
      {
        path: "/Cashier",
        name: "cashier",
        component: Cashier,
        meta: {
          title: "Cashier",
        },
        ...preventRoute,
      },
      {
        path: "/Games",
        name: "games",
        component: CashierOptions,
        meta: {
          title: "Games",
        },
        ...preventRoute,
      },
      {
        path: "/RecallHistory",
        name: "recall-history",
        component: RecallHistory,
        meta: {
          title: "RecallHistory",
        },
        ...preventRoute,
      },

      {
        path: "/Config",
        name: "config",
        component: Config,
        ...preventRoute,
        meta: {
          title: "Config",
        },
      },
    ],
  },
];

const router = new VueRouter({
  mode: "history",
  routes,
  scrollBehavior: () => {
    return {
      x: 0,
      y: 0,
    };
  },
});
router.beforeEach((to, from, next) => {
  document.title = `Employee | ${to.meta.title}`;
  next();
});

export default router;
