<template>
  <div>
    <div class="flex space-x-1 text-yellow-400 items-center" v-if="isValid">
      <p class="countdown-number">0{{ minutes }}</p>
      <p class="countdown-colon">:</p>
      <p v-if="seconds >= 10" class="countdown-number">{{ seconds }}</p>
      <p v-if="seconds < 10" class="countdown-number">0{{ seconds }}</p>
    </div>
    <div v-else>00:00</div>
  </div>
</template>

<script>
export default {
  props: {
    startTime: {
      type: Number,
      required: true,
    },
    finishTime: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      minutes: 0,
      seconds: 0,
      intervalId: null,
    };
  },
  mounted() {
    this.updateCountdown();

    // Update the countdown every second
    this.intervalId = setInterval(this.updateCountdown, 1000);
  },
  beforeDestroy() {
    // Clear the interval when the component is destroyed
    clearInterval(this.intervalId);
  },
  computed: {
    isValid() {
      return this.finishTime > this.startTime;
    },

    // computed: {
    formatMinutes() {
      return this.addLeadingZero(this.minutes);
    },
    formatSeconds() {
      return this.addLeadingZero(this.seconds);
    },
    // }
  },
  methods: {
    addLeadingZero(value) {
      if (value < 10) {
        return "0" + value;
      }
      return value.toString();
    },
    updateCountdown() {
      const now = new Date().getTime();
      const timeDifference = this.finishTime - now;

      // Calculate minutes and seconds
      this.minutes = Math.floor(
        (timeDifference % (1000 * 60 * 60)) / (1000 * 60)
      );
      this.seconds = Math.floor((timeDifference % (1000 * 60)) / 1000);

      // If the countdown reaches zero, clear the interval
      if (timeDifference <= 0) {
        clearInterval(this.intervalId);
      }
    },
  },
};
</script>
<style>
.countdown-number {
  margin: 0;
  padding: 0;
  font-size: 16px;
}

.countdown-colon {
  margin: 0;
  padding: 0;
  font-size: 16px;
  font-weight: bold;
}
</style>
