<template>
  <div class="text-white flex justify-center">
    <div>
      <div class="font-bold">
        <h1 class="flex justify-center mb-4 text-3xl">Opps!</h1>
      </div>
      <img src="../assets/noConnection.avif" alt="" class="rounded-lg h-96" />

      <div class="flex justify-center cursor-pointer">
        <BaseButton
          @click="Reload()"
          buttonClass="bg-1"
          class="text-base m-5 mx-1 flex justify-center w-48 bg-1 py-2 px-4 rounded"
          >Reload</BaseButton
        >
      </div>
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    Reload() {
      location.reload();
    },
  },
};
</script>

<style></style>
