<template>
  <div id="Myfont" class="p-8 print:p-0 bg-darkcard text-white">
    <div class="flex justify-between mx-auto">
      <div class="p-2 text-3xl text-yellow-500">Instructions</div>
      <img
        @click="modal()"
        class="cursor-pointer mr-8"
        src="../../assets/icons/x.svg"
        alt=""
        srcset=""
      />
    </div>

    <div>
      <div class="bg-darkbg text-yellow-400 text-xl p-2 mt-2">
        For creating spot(selecting number)
      </div>
      <div class="bg-darkbg text-base text-gray-300 tracking-wide p-2 mt-">
        <!-- <li>Automatically the page is spots input is focused</li> -->
        <li>
          To begin selecting numbers, please type a number and hit enter. If you
          would like to add another number, simply type the number and hit enter
          again.
        </li>
      </div>
      <div class="bg-darkbg text-yellow-400 text-xl p-2 mt-2">
        For placing cash
      </div>
      <div class="bg-darkbg text-base text-gray-300 tracking-wide p-2 mt-">
        <!-- <li>Automatically the page is spots input is focused</li> -->
        <li>
          To add money, please press the Tab key once and then type the amount
          of cash. Finally, hit the enter key to proceed.
        </li>
      </div>
      <div class="bg-darkbg text-yellow-400 text-xl p-2 mt-2">
        For creating Ticket
      </div>
      <div class="bg-darkbg text-base text-gray-300 tracking-wide p-2 mt-">
        <!-- <li>Automatically the page is spots input is focused</li> -->
        <!-- <ol> -->
        <li>
          Here are the two probabilities for adding another bet:
          <br />
          1, If the user wants to select another number: The number selecting
          input is automatically selected, so you can type the spot and hit
          enter.
        </li>
        <p>
          2, If the user wants to create a new bet: Press the Tab key three
          times and then hit enter.
        </p>
        <!-- </ol> -->
      </div>
      <!-- <button @click="printPage()">Print</button> -->
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  computed: {
    ...mapGetters("modals", ["instructionStatus"]),
  },
  methods: {
    printPage() {
      print();
    },
    modal() {
      console.log("ove");
      this.$store.dispatch("modals/modal", {
        stat: "instructions",
        status: !this.instructionStatus,
      });
      this.$parent.focusInput();
    },
  },
};
</script>

<style></style>
