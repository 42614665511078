/* eslint-disable */
import Vue from "vue";
export const namespaced = true;
export const state = {
  addEmployee: false,
  editEmployee: false,
  addBranch: false,
  editBranch: false,
  printer: false,
  printer56P: false,
  instructions: false,
  searchOpenModal: false,
  gameDetails: false,
};

export const actions = {
  modal({ state }, { stat, status }) {
    if (stat) {
      state[stat] = status;
      // console.log("fired: ", stat, status);
      return true;
    }
  },
  closeAll({ state }) {
    Object.keys(state).forEach((key) => {
      state[key] = false;
    });
  },
};
export const getters = {
  addemployee: (state) => state.addEmployee,
  editemployee: (state) => state.editEmployee,
  addbranch: (state) => state.addBranch,
  editbranch: (state) => state.editBranch,
  printerStatus: (state) => state.printer,
  printerStatus56P: (state) => state.printer56P,
  instructionStatus: (state) => state.instructions,
  searchOpenModalStatus: (state) => state.searchOpenModal,
  gameDetailsStatus: (state) => state.gameDetails,
};
