/* eslint-disable */
import CryptoJS from "crypto-js";
const key = "loveIsAGameForFool";
import ErrorHandler from "@/services/ErrorHandler";
// import Vue from "vue";
import AxiosService from "../../services/AxiosServices";
import AuthenticationServices from "@/services/AuthenticationServices";
// import router from "@/router";
export const namespaced = true;
export const state = {
  userInfo: [],
  userToken: null,
  me: [],
  userRole: "",
  nightMode: "",
};

export const mutations = {
  USER_TOKEN(state, token) {
    state.userToken = token;
  },
  USER_INFO(state, userInfo) {
    state.userInfo = userInfo;
  },
};
export const actions = {
  signUp({ commit, dispatch }, payload) {
    return AuthenticationServices.postSignup(payload)
      .then((res) => {
        const notification = {
          type: "successs",
          message:
            "Your account has been created!,please now login to continue",
        };
        dispatch("notification/add", notification, { root: true });
        return res;
      })
      .catch((error) => {
        ErrorHandler(error);
        return false;
      });
  },
  getMe({ state }) {
    return AxiosService.getMe()
      .then((res) => {
        state.me = res.data.user;
        return res.data.user;
      })
      .catch((error) => {
        ErrorHandler(error);
        return false;
      });
  },
  updateMyProfile({ dispatch }, payload) {
    return AxiosService.updateMyProfile(payload)
      .then((res) => {
        if (res) {
          AxiosService.getMe().then((res) => {
            state.me = res.data.user;
            // console.log("user: ", res.data.user);
            let user = {
              id: res.data.user._id,
              employeeName: res.data.user.employeeName,
              email: res.data.user.email,
              role: res.data.user.role,
              pic: res.data.user.photos,
              phoneNumber: res.data.user.phoneNumber,
            };
            localStorage.setItem("user", JSON.stringify(user));
            return true;
          });
        }
        const notification = {
          type: "success",
          message: "Profile updated successfully",
        };
        dispatch("notification/add", notification, { root: true });
        return true;
      })
      .catch((error) => {
        ErrorHandler(error);
        return false;
      });
  },
  updateMyPassword({ dispatch }, formdata) {
    return AxiosService.updateMyPassword(formdata)
      .then((res) => {
        const notification = {
          type: "success",
          message: "Password updated successfully",
        };
        dispatch("notification/add", notification, { root: true });
        return true;
      })
      .catch((error) => {
        ErrorHandler(error);
        return false;
      });
  },

  sendForDevTeam({ dispatch }, formdata) {
    return AxiosService.sendForDevTeam(formdata)
      .then((res) => {
        const notification = {
          type: "success",
          message: "Message sent successfully! Thank you for your comment! ",
        };
        dispatch("notification/add", notification, { root: true });
        return true;
      })
      .catch((error) => {
        ErrorHandler(error);
        return false;
      });
  },
  async signIn({ state, commit, dispatch }, payload) {
    return AuthenticationServices.postLogin(payload)
      .then((res) => {
        if (res.data.status === "success") {
          let user = {
            id: res.data.user._id,
            agentName: res.data.user.agentName,
            employeeName: res.data.user.employeeName,
            email: res.data.user.email,
            role: res.data.user.role,
            pic: res.data.user.photos,
            phoneNumber: res.data.user.phoneNumber,
          };
          const encryptedToken = CryptoJS.AES.encrypt(
            res.data.token,
            key
          ).toString();
          localStorage.setItem("encryptedCashierToken", encryptedToken);
          localStorage.setItem("user", JSON.stringify(user));
          commit("USER_INFO", res.data.user);
          commit("USER_TOKEN", res.data.token);
          state.nightMode = res.data.user.nightMode;
          // console.log("night Mode", state.nightMode);

          return true;
        } else if (res.data.status === "activate") {
          const notification = {
            type: "error",
            message: error.response.data.message,
          };
          dispatch("notification/add", notification, { root: true });
          return false;
        }
      })
      .catch((error) => {
        if (error.code == "ERR_BAD_REQUEST") {
          if (error.response.data.error.statusCode == 401) {
            localStorage.clear();
            // router.push("/");
            const notification = {
              type: "error",
              message: error.response.data.message,
            };
            dispatch("notification/add", notification, { root: true });
            return false;
          } else {
            const notification = {
              type: "error",
              message: error.response.data.message,
            };
            dispatch("notification/add", notification, { root: true });
            return false;
          }
        } else {
          ErrorHandler(error);
          return false;
        }
      });
  },

  // Forget Password
  forgetPassword({ commit, dispatch }, payload) {
    // console.log(payload);
    return AuthenticationServices.forgetPassword(payload)
      .then((res) => {
        const notification = {
          type: "success",
          message:
            "we have sent password reset code Please check your email account!",
        };
        dispatch("notification/add", notification, { root: true });
        return true;
      })
      .catch((error) => {
        ErrorHandler(error);
        return false;
      });
  },
  ResetPassword({ commit, dispatch }, payload) {
    // console.log(payload);
    return AuthenticationServices.ResetPassword(payload)
      .then((res) => {
        // console.log("res status sts: ", res.data.status);
        // console.log("res  sts: ", res);
        if (res.data.status === "success") {
          localStorage.clear();
          let user = {
            id: res.data.user._id,
            employeeName: res.data.user.employeeName,
            email: res.data.user.email,
            role: res.data.user.role,
            pic: res.data.user.photos,
            phoneNumber: res.data.user.phoneNumber,
          };
          const encryptedToken = CryptoJS.AES.encrypt(
            res.data.token,
            key
          ).toString();
          localStorage.setItem("encryptedCashierToken", encryptedToken);
          // localStorage.setItem("encryptedCashierToken", res.data.token);
          localStorage.setItem("user", JSON.stringify(user));
          commit("USER_INFO", res.data.user);
          commit("USER_TOKEN", res.data.token);
          const notification = {
            type: "success",
            message: "you have successful rested your password and logged in!",
          };
          dispatch("notification/add", notification, { root: true });
          return true;
        } else if (res.data.status === "activate") {
          const notification = {
            type: "error",
            message:
              "Your account is not active please contact your branch manager!!!",
          };
          dispatch("notification/add", notification, { root: true });
          return false;
        }
      })
      .catch((error) => {
        ErrorHandler(error);
        return false;
      });
  },
  ResetPasswordByLink({ commit, dispatch }, { token, formdata }) {
    return AuthenticationServices.ResetPasswordByLink({ token, formdata })
      .then((res) => {
        if (res.data.status === "success") {
          localStorage.clear();
          let user = {
            id: res.data.user._id,
            employeeName: res.data.user.employeeName,
            email: res.data.user.email,
            role: res.data.user.role,
            pic: res.data.user.photos,
            phoneNumber: res.data.user.phoneNumber,
          };
          const encryptedToken = CryptoJS.AES.encrypt(
            res.data.token,
            key
          ).toString();
          localStorage.setItem("encryptedCashierToken", encryptedToken);
          // localStorage.setItem("encryptedCashierToken", res.data.token);
          localStorage.setItem("user", JSON.stringify(user));
          commit("USER_INFO", res.data.user);
          commit("USER_TOKEN", res.data.token);
          const notification = {
            type: "success",
            message:
              "you have successfully rested your password and logged in!",
          };
          dispatch("notification/add", notification, { root: true });
          return true;
        } else if (res.data.status === "activate") {
          const notification = {
            type: "error",
            message: error.response.data.message,
          };
          dispatch("notification/add", notification, { root: true });
          return false;
        }
      })
      .catch((error) => {
        ErrorHandler(error);
        return false;
      });
  },
  logOut({ dispatch }) {
    const notification = {
      type: "logout",
      message: "You have signed off successfully",
    };
    dispatch("notification/add", notification, { root: true });
  },
};
export const getters = {
  // nightMode: (state) => state.users.nightMode,
};

export default {};
