<template>
  <div>
    <div class="print:block hidden">
      <DuplicateTicket :print="tick" v-if="printer" />
    </div>
    <div class="print:hidden">
      <div class="flex justify-center py-4" v-if="noConnection">
        <network-issue />
      </div>

      <div
        v-if="!spinner && !noConnection"
        class="bg-darkcard mx-4 my-2 text-gray-300 rounded-md p-3"
      >
        <BaseModal
          callBack="fetchTickets"
          modalClass="md:w-3/4 w-11/12"
          :visible="gameDetailsStatus"
        >
          <GameRecall class="w-full" :info="modalData"
        /></BaseModal>
        <div class="grid grid-cols-7 text-base">
          <div class="col-span-5">Tickets</div>
          <div class="flex space-x-3 col-span-2">
            <BaseInput
              @keypress.enter="searchTicket()"
              autocomplete="off"
              placeholder="search ticket"
              type="Text"
              inputClass="px-2 bg-darkbg outline-none w-full p-1 rounded "
              v-model="search"
            />
            <BaseButton
              v-if="search"
              class="text-red-600"
              @click="getAllAfterSearch()"
              >cancel</BaseButton
            >
          </div>
        </div>
        <div class="bg-darkbg h-full my-2 rounded-xl">
          <table class="table-auto w-full text-sm">
            <thead>
              <tr class="space-x-8 text-left font-light">
                <th class="border-b border-b-gray-700 px-4 font-light py-2">
                  #
                </th>
                <!-- <th class="px-4 border-b border-b-gray-700 py-2 font-light">
                  BetID
                </th> -->
                <th class="px-4 border-b border-b-gray-700 py-2 font-light">
                  Game Number
                </th>
                <th class="px-4 border-b border-b-gray-700 py-2 font-light">
                  Total Odds
                </th>
                <th class="px-4 border-b border-b-gray-700 py-2 font-light">
                  Bet Money
                </th>
                <th class="px-4 border-b border-b-gray-700 py-2 font-light">
                  Possible Payout
                </th>
                <th class="px-4 border-b border-b-gray-700 py-2 font-light">
                  Branch
                </th>
                <th class="px-4 border-b border-b-gray-700 py-2 font-light">
                  Prize Money
                </th>
                <th class="px-4 border-b border-b-gray-700 py-2 font-light">
                  Payout
                </th>

                <th class="px-4 border-b border-b-gray-700 py-2 font-light">
                  Reject Ticket
                </th>
                <th class="px-4 border-b border-b-gray-700 py-2 font-light">
                  Print
                </th>
                <th class="px-4 border-b border-b-gray-700 py-2 font-light">
                  More
                </th>
              </tr>
            </thead>

            <tbody>
              <tr
                v-if="watchedTickets.length !== 0 && !spinner2"
                v-for="(tick, index) in watchedTickets"
                :key="index"
                class="text-gray-400"
              >
                <td class="px-4 py-2">{{ index + 1 }}</td>
                <!-- <td class="px-4 py-2">{{ tick.customId }}</td> -->
                <td class="px-4 py-2">
                  <p v-if="tick.gameId">
                    {{ tick.gameId.customId }}
                  </p>
                  <p v-if="!tick.gameId">---</p>
                </td>
                <td class="px-4 py-2">{{ tick.totalOdd }}</td>
                <td class="px-4 py-2">
                  {{ tick.totalPlacedMoney.toLocaleString() }} birr
                </td>
                <td class="px-4 py-2">
                  {{ tick.maxPossiblePayout.toLocaleString() }} birr
                </td>
                <td class="px-4 py-2">
                  <p v-if="tick.placedBranch">
                    {{ tick.placedBranch.branchLocation }}
                  </p>
                  <p v-if="!tick.placedBranch">---</p>
                </td>
                <td class="px-4 py-2 text-green-500">
                  <!-- <p
                    v-if="tick.gameId.status == 'pending'"
                    class="bg-yellow-500 w-1/2 text-white"
                  >
                    Pending
                  </p> -->
                  <p v-if="tick.totalPrizeMoney" class="text-redo">
                    {{ tick.totalPrizeMoney.toLocaleString() }} birr
                  </p>
                  <p class="text-green-500" v-if="tick.totalPrizeMoney == 0">
                    0
                  </p>
                  <!-- <p class="text-red-500" v-if="!tick.totalPrizeMoney">Pending</p> -->
                </td>
                <td class="px-4 text-right flex space-x-2 py-2">
                  <img
                    v-if="tick.tookPrizeMoney == true"
                    class="w-4 h-4"
                    src="../../assets/icons/paidout.svg"
                    alt=""
                    srcset=""
                  />
                  <p v-if="tick.tookPrizeMoney == true">Yes</p>
                  <p v-if="tick.tookPrizeMoney == false">No</p>
                </td>

                <td class="px-4 text-left -2 py-2">
                  <div v-if="tick.gameId.status == 'pending'">
                    <p
                      :class="{ 'cursor-not-allowed': ticketAddingLoader }"
                      @click="RejectBet(tick)"
                      class="fa fa-times fa-1.5x p-1 hover:text-white hover:bg-red-500 p cursor-pointer rounded-md px-2 text-redo"
                    ></p>
                  </div>
                  <div v-if="tick.gameId.status == 'finished'">
                    <p class="">----</p>
                  </div>
                </td>
                <td class="px-4 text-left -2 py-2">
                  <div>
                    <p
                      :class="{ 'cursor-not-allowed': ticketAddingLoader }"
                      @click="duplicateTicket(tick)"
                      class="fa fa-print fa-1.5x p-1 hover:text-white hover:bg-yellow-500 p cursor-pointer rounded-md px-2 text-yellow-500"
                    ></p>
                  </div>
                </td>
                <td class="px-4 py-2">
                  <p @click="TicketDetails(tick)">More</p>
                </td>
              </tr>
            </tbody>
          </table>
          <!-- {{ tableBodyHeight }} -->
          <div
            style="height"
            :style="`heigh:${tableBodyHeight}`"
            v-if="spinner2"
          >
            <div class="flex justify-center py-16">
              <spinner2 class="flex justify-center" />
            </div>
          </div>
          <div
            class="text-3xl py-24 w-full text-center"
            v-if="tickets.length === 0"
          >
            There are no tickets available!
          </div>
        </div>
        <!-- <div
          v-if="countTickets > 12 && watchedTickets.length !== 0"
          class="text-xs flex justify-end"
         >
          <div class="flex space-x-">
            <template v-if="page !== 1">
              <div
                class="cursor-pointer"
                @click="paginate(page - 1)"
                rel="prev"
              >
                ← Prev Page
              </div>
            </template>
            <div
              class="text-sm cursor-pointer px-4"
              @click="paginate(currentPage)"
              v-for="(currentPage, i) in letsTry"
              :key="i"
              :class="{
                'bg-5  rounded-md text-white text-center': page == currentPage,
              }"
            >
              {{ currentPage }}
            </div>
            <template v-if="page < letsTry.length">
              <div
                @click="paginate(page + 1)"
                rel="prev"
                class="cursor-pointer"
              >
                Next Page →
              </div>
            </template>
          </div>
        </div> -->
        <div
          v-if="countTickets > 13 && watchedTickets.length !== 0"
          class="text-xs"
        >
          <base-pagination :itemsCount="length" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import DuplicateTicket from "@/components/DuplicateTicket.vue";
import { mapState, mapGetters } from "vuex";
import spinner from "@/components/spinner.vue";
import spinner2 from "@/components/spinner2.vue";
import NetworkIssue from "@/Error/NetworkIssue.vue";
import GameRecall from "@/components/Modal/GameRecall.vue";
export default {
  components: {
    spinner,
    NetworkIssue,
    spinner2,
    DuplicateTicket,
    GameRecall,
  },
  data() {
    return {
      modalData: [],
      DrawnNumber: [],
      tick: [],
      spinner2: false,
      printer: false,
      noConnection: false,
      spinner: false,
      search: "",
      tickets: [],
      watchedTickets: [],
      tableBodyHeight: 0,
      CodeSetting: "",
      ticketAddingLoader: false,
    };
  },
  watch: {
    tickets(newValue) {
      this.watchedTickets = newValue;
      // console.log("newValue: ".newValue);
    },
  },
  created() {
    // this.$store.dispatch("cashier/clearPrint");
    // this.setPrinterSettingToVuex();
    this.spinner = true;
    this.fetchTickets();
    // this.setPrinterSettingToVuex();
    this.$store.dispatch("cashier/getCountAllTickets");
  },
  mounted() {
    const navbarHeight = 8; // Replace with your navbar height
    const tableHeaderHeight = 12; // Replace with your table header height
    const paginationHeight = 8; // Replace with your pagination height

    this.tableBodyHeight =
      window.innerHeight - navbarHeight - tableHeaderHeight - paginationHeight;
  },
  computed: {
    ...mapState("cashier", ["countTickets"]),
    ...mapGetters("modals", ["gameDetailsStatus"]),
    length() {
      let count = 0;
      if (26 > this.countTickets > 13) {
        count = 26;
      } else {
        count = 39;
      }
      return count;
    },
    page() {
      return parseInt(this.$route.query.page) || 1;
    },
  },
  methods: {
    fetchTickets() {
      this.$store
        .dispatch("cashier/getAllTickets", {
          perPage: 12,
          page: this.page,
        })
        .then((response) => {
          // console.log("response:", response);
          if (response) {
            this.spinner = false;
            this.tickets = response;
          } else {
            this.spinner = false;
            this.noConnection = true;
          }
        })
        .catch((err) => {
          this.spinner2 = false;
          this.networkIssue = true;
        });
    },
    TicketDetails(tick) {
      this.modalData = tick;
      this.modal();
    },
    paginate(selectedPage) {
      this.spinner2 = true;
      this.$router.push({ query: { page: selectedPage } });
      this.$store
        .dispatch("cashier/getAllTickets", {
          perPage: 12,
          page: this.page,
        })
        .then((response) => {
          if (response) {
            this.tickets = response;
            this.spinner2 = false;
          } else {
            this.spinner2 = false;
            this.networkIssue = true;
          }
        })
        .catch((err) => {
          this.spinner2 = false;
          this.networkIssue = true;
        });
    },
    setPrinterSettingToVuex() {
      const code = localStorage.getItem("CodeSetting");
      this.$store.state.cashier.codeSetting = code;
    },
    searchTicket() {
      this.$store
        .dispatch("cashier/searchTicket", this.search)
        .then((response) => {
          if (response) {
            // console.log("response: ", response);
            this.tickets = response;
          }
        });
    },
    createTicketAgain(id) {
      this.ticketAddingLoader = true;
      this.$store
        .dispatch("cashier/createTicketAgain", id)
        .then((response) => {
          if (response) {
            this.ticketAddingLoader = false;
            this.getAllTickets();
          } else {
            this.ticketAddingLoader = false;
          }
        })
        .catch((error) => {
          this.ticketAddingLoader = false;
        });
    },
    duplicateTicket(tick) {
      // const code = localStorage.getItem("CodeSetting");
      // if (code) {

      // console.log("here");
      this.tick = tick;
      this.printer = true;

      // console.log("tick: ", tick);
      // } else {
      //   const alert = {
      //     type: "error",
      //     message: "first please select code type!",
      //   };
      //   this.$store.dispatch("notification/add", alert);
      // }
    },
    RejectBet(tick) {
      if (tick.id) {
        this.$store.dispatch("cashier/RejectBet", tick.id).then((response) => {
          if (response) {
            this.fetchTickets();
            const alert = {
              type: "success",
              message: "Ticket Canceled!",
            };
            this.$store.dispatch("notification/add", alert);
          } else {
            this.fetchTickets();
          }
        });
      }
    },
    getAllAfterSearch() {
      this.search = "";
      this.$store
        .dispatch("cashier/getAllTickets", {
          perPage: 12,
          page: this.page,
        })
        .then((response) => {
          if (response) {
            this.watchedTickets = response;
          }
        });
    },
    modal() {
      this.$store.dispatch("modals/modal", {
        stat: "gameDetails",
        status: !this.gameDetailsStatus,
      });
    },
  },
};
</script>

<style></style>
