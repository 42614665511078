<template>
  <div
    id="Myfont"
    class="w-full print:w-printerSize print:left-0 print:top-0 print:w- p-4 print:p-0 print:m-0 text-white print:text-black"
  >
    <div class="print:block print:w-printerSize hidden text-start">
      <div class="print:text-start">
        <div class="text-text text-lg flex justify-center">
          <img
            class="h-14 w-14 mt-3"
            src="../../../public/logo.svg"
            alt=""
            srcset=""
          />
        </div>
        <div class="text-center text-2xl text-black font-extrabold">
          KenoPro
        </div>
      </div>
      <div class="text-center text-xl text-black">Ticket Result</div>
    </div>
    <div class="flex justify-between mx-auto mb-5 print:hidden">
      <p class="text-gray-400">Search Ticket</p>

      <img
        @click="modalClose()"
        class="cursor-pointer"
        src="../../assets/icons/x.svg"
        alt=""
        srcset=""
      />
    </div>
    <!-- <form @submit.prevent="onSubmit" class="w-full"> -->
    <div class="flex justify-center space-x-3 print:hidden">
      <!-- @keypress="searchTicket()" -->

      <input
        @keypress.enter="searchTicket()"
        ref="idInput"
        label=""
        :class="{ 'border border-redo': noInput }"
        class="bg-darkbg text-white outline-none w-1/2 p-2 rounded"
        v-model="id"
      />

      <div class="flex justify-center m-auto p-auto">
        <button
          class="cursor-pointer px-4 text-sm p-2 w-20 rounded-md flex justify-center space-x-3 bg-text"
          @click="searchTicket()"
        >
          <BaseSpinner v-if="searchRequestSent" class="text-center" />
          <p v-if="!searchRequestSent">Search</p>
        </button>
      </div>
    </div>
    <!-- </form> -->
    <!-- {{ barCode }} -->
    <p v-if="noInput" class="text-redo mx-32 text-sm text-start">
      Please enter ticket id!
    </p>
    <div
      v-if="search == true && returnGameStatus == 'finished'"
      class="text-right print:hidden mt-3"
    >
      <BaseButton
        buttonClass="bg-green-500 pri p-2 rounded-md w-24"
        @click="print()"
        ><i class="fa fa-print"></i>Print</BaseButton
      >
    </div>

    <div
      v-if="search == true && returnGameStatus == 'finished'"
      class="p-8 print:p-3 print:w-printerSize print:px-3 gap-5 text-center bg-darkbg mt-2"
    >
      <div class="flex text-center space-x-3 text-green-500 print:text-black">
        <div>Payout:</div>
        <div>{{ barCode.totalPrizeMoney.toLocaleString() }} birr</div>
      </div>
      <div class="flex text-left space-x-3">
        <div>Custom Id:</div>
        <div>{{ barCode.customId }}</div>
      </div>
      <div class="flex text-left space-x-3">
        <div>Game Status:</div>
        <div>{{ barCode.gameId.status }}</div>
      </div>
      <div
        v-if="barCode.totalPrizeMoney !== 0"
        class="flex text-center space-x-3"
      >
        <div>Took prize money:</div>
        <div v-if="!barCode.tookPrizeMoney" class="text-red-500">
          {{ barCode.tookPrizeMoney }}
        </div>
        <div v-if="barCode.tookPrizeMoney" class="text-green-500">
          {{ barCode.tookPrizeMoney }}
        </div>
      </div>
      <div class="flex text-center space-x-3">
        <div>Bet Money:</div>
        <div>{{ barCode.totalPlacedMoney.toLocaleString() }} birr</div>
      </div>

      <!-- totalPrizeMoney -->
      <div class="flex text-center space-x-3">
        <div>Game Id:</div>
        <div>{{ barCode.gameId.customId }}</div>
      </div>
      <div class="flex text-center space-x-3">
        <div>Date:</div>
        <div>{{ barCode.placedTime }}</div>
      </div>
    </div>
    <div
      v-if="search == false && !noTicketFound"
      class="p-8 gap-5 bg-darkbg mt-2"
    >
      <p>scan or type the ticket id!</p>
    </div>
    <p
      v-if="returnGameStatus == 'pending'"
      class="text-redo mx-32 text-base text-start p-8 gap-5 bg-darkbg mt-2"
    >
      Game is pending!
    </p>
    <div v-if="noTicketFound" class="p-8 gap-5 bg-darkbg text-redo mt-2">
      No ticket found with that id!
    </div>
    <div class="w-full print:hidden" v-if="search">
      <!-- bar.codeType.totalPayoutMoney !== 0 -->
      <div
        v-if="statusRequestSent"
        class="'w-full cursor-pointer flex justify-center text-base mt-5 py-2 bg-text"
      >
        <BaseSpinner />
      </div>
      <div
        v-if="returnGameStatus == 'pending'"
        class="text-center print:hidden mt-3"
      >
        <BaseButton
          :disabled="rejectTicketLoader"
          buttonClass="bg-redo pri p-2 flex space-x-3 text-sm rounded-md w-36"
          @click="rejectTicket()"
          ><i class="fa fa-times"></i>
          <p>Reject Ticket</p></BaseButton
        >
      </div>
      <BaseButton
        :disabled="searchRequestSent && statusRequestSent"
        @click="changeStatus()"
        v-if="
          barCode.tookPrizeMoney == false &&
          barCode.totalPrizeMoney !== 0 &&
          barCode.gameId.status == 'finished' &&
          !statusRequestSent
        "
        :buttonClass="{
          'w-full  cursor-pointer flex justify-center text-base mt-5 py-2 bg-text':
            !statusRequestSent,
          'cursor-not-allowed w-full flex justify-center text-base mt-5 py-2 bg-text':
            statusRequestSent,
        }"
      >
        Pay Out</BaseButton
      >
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  data() {
    return {
      noInput: false,
      rejectTicketLoader: false,
      id: "",
      search: false,
      searchRequestSent: false,
      statusRequestSent: false,
      noTicketFound: false,
      barCode: [],
    };
  },
  mounted() {
    this.$nextTick(() => {
      this.$refs.idInput.focus();
    });
  },
  computed: {
    returnGameStatus() {
      if (this.barCode.gameId) {
        return this.barCode.gameId.status;
      }
    },
    // ...mapState("cashier", ["barCode"]),
  },
  methods: {
    searchTicket() {
      if (this.id) {
        this.noInput = false;
        this.noTicketFound = false;
        this.searchRequestSent = true;
        this.$store
          .dispatch("cashier/searchTicketUsingBarCode", this.id)
          .then((res) => {
            if (res) {
              this.searchRequestSent = false;
              this.noTicketFound = false;
              this.barCode = res[0];
              this.search = true;
              if (!res[0]) {
                this.barCode = [];
                this.search = false;
                // console.log("here", this.barCode);
                this.noTicketFound = true;
              }
            }
            this.searchRequestSent = false;
          });
      } else {
        this.noInput = true;
      }

      // console.log("id: ", this.id);
    },
    rejectTicket() {
      if (this.barCode) {
        this.rejectTicketLoader = true;
        // this.rejectTicketSent = true;
        this.$store
          .dispatch("cashier/RejectBet", this.barCode.id)
          .then((response) => {
            if (response) {
              const alert = {
                type: "success",
                message: "Ticket Canceled!",
              };
              this.$store.dispatch("notification/add", alert);
              this.barCode = [];
              this.id = "";
              this.rejectTicketLoader = false;
              this.search = false;
              this.noTicketFound = false;
            } else {
              this.rejectTicketLoader = false;
            }
          })
          .catch((error) => {
            this.rejectTicketLoader = false;
          });
      }
    },
    print() {
      window.print();
    },
    changeStatus() {
      this.statusRequestSent = true;
      this.$store
        .dispatch("cashier/changeStatus", this.barCode.customId)
        .then((res) => {
          if (res) {
            this.statusRequestSent = false;
            // console.log(res);
            this.barCode = res[0];
            this.search = true;
            this.id = "";
          } else {
            this.statusRequestSent = false;
          }
        })
        .catch((error) => {
          this.statusRequestSent = false;
        });
      // console.log("id: ", this.id);
    },
    modalClose() {
      this.search = false;
      this.$store.dispatch("modals/closeAll");
      this.$store.dispatch("cashier/getAllTickets", {
        perPage: 10,
        page: 1,
      });
    },
  },
};
</script>

<style></style>
