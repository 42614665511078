<template>
  <div class="w-printerSize print:left-0 print:top-0 bg-white">
    <div class="">
      <div class="bg-white space-y-2 p-1 divide-y divide-black">
        <div class="flex justify-between">
          <div>
            <div class="text-text text-lg flex justify-center">
              <img
                class="h-14 w-14 mt-3"
                src="../../public//logo.svg"
                alt=""
                srcset=""
              />
            </div>
            <div class="text-center text-2xl text-black font-extrabold">
              KenoPro
            </div>
          </div>
          <div>
            <div class="flex justify-between mt-2 font-bold">
              <div class="text-sm">
                <!-- <p>ID: {{ spot.customId }}</p> -->
                <p>Game ID: {{ data.gameId.customId }}</p>
                <p>Branch: {{ data.placedBranch.branchLocation }}</p>
                <!-- <p>Agent: {{ data.placedBranch.agentName }}</p> -->
                <p>
                  {{ data.placedCashier.employeeName }}
                </p>
                <p>{{ data.placedTime }}</p>
                <p>Duplicate Ticket</p>
              </div>
            </div>
          </div>
        </div>
        <div class="container">
          <table class="table-fixed">
            <thead>
              <tr>
                <th class="px-2 border border-black">Spots</th>
                <th class="pl-1 border border-black">Stake</th>
                <th class="pl-1 border border-black">Payout</th>
              </tr>
            </thead>
            <tbody>
              <tr class="">
                <td class="border border-b border-black w-80">
                  <div
                    class="flex space-x-1"
                    v-for="(ss, index) in data.game"
                    :key="index"
                  >
                    <i
                      style="font-size: xx-small"
                      class="mt-1 fa fa-asterisk"
                    ></i>
                    <div
                      class="flex justify-start divide-y"
                      v-for="(s, index) in ss.selectedNumbers"
                      :key="index"
                    >
                      <div class="text-base mr-1">{{ s.number }},</div>
                    </div>
                  </div>
                </td>
                <td class="border border-b border-black w-20">
                  <div v-for="(s, index) in data.game" :key="index">
                    <div class="text-base px-1">{{ s.placedMoney }}</div>
                  </div>
                </td>
                <td class="border border-b border-black w-24 px-1">
                  <div v-for="(s, index) in data.game" :key="index">
                    <div class="text-base">
                      {{ s.possiblePayout.toLocaleString() }}
                    </div>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        <div class="flex justify-between w-full text-xs">
          <div class="flex space text-base font-bold mt-2">
            <p>Total Stake = {{ data.totalPlacedMoney }}</p>
          </div>
          <div class="flex">
            <p class="font-bold mt-2 text-base">
              Total Payout = {{ data.maxPossiblePayout.toLocaleString() }}
            </p>
          </div>
        </div>
        <div class="text-sm text-center">
          <div class="w-full">
            <img
              @load="runInParent"
              class="w-full h-24"
              :src="`${URL}/${data.barCodePath}`"
              alt=""
            />
          </div>
        </div>
      </div>
    </div>
    <div class="text-sm text-center font-bold">
      powered by Proxima Technologies
    </div>
    <div class="text-xs text-center flex justify-center space-x-2">
      <p>To be an agent contact us</p>

      <p class="font-bold tracking-wide">0944084448</p>
    </div>
    <BaseButton
      @click="PrintPage()"
      class="text-center print:hidden w-full bg-text rounded-md text-white"
      ><i class="fa fa-print"></i>Print</BaseButton
    >
  </div>
</template>

<script>
const API_URL = process.env.VUE_APP_API_BASE_URL;
export default {
  data() {
    return {
      URL: [],
      data: [],
    };
  },
  props: {
    print: {
      required: true,
    },
  },
  created() {
    this.data = this.print;
  },
  mounted() {
    this.URL = process.env.VUE_APP_API_BASE_URL;
  },
  computed: {},
  methods: {
    runInParent() {
      window.print();
      // this.print = [];
      this.data = [];
      this.$parent.printer = false;
    },
  },
};
</script>

<style></style>
