import store from "@/store/store";
import router from "@/router";

function ErrorHandler(error) {
  if (error.code) {
    if (error.code == "ERR_NETWORK") {
      const notification = {
        type: "error",
        message:
          "It seems there is network issue please check your connection!",
      };
      store.dispatch("notification/add", notification);
    } else if (error.code == "ECONNABORTED") {
      const notification = {
        type: "error",
        message: "Please try again!",
      };
      store.dispatch("notification/add", notification);
    } else if (error.code == "ERR_BAD_REQUEST") {
      if (error.response.data.error.statusCode == 401) {
        if (router.currentRoute.path === "/") {
          console.log("here here");
          const notification = {
            type: "error",
            message: error.response.data.message,
          };
          store.dispatch("notification/add", notification);
          // User is already on the desired route, no need for redundant navigation
          return false;
        } else {
          console.log("here hesssre");
          localStorage.clear();
          router.push("/");
          const notification = {
            type: "error",
            message: error.response.data.message,
          };
          store.dispatch("notification/add", notification);
          return false;
        }
      } else {
        const notification = {
          type: "error",
          message: error.response.data.message,
        };
        store.dispatch("notification/add", notification);
        return false;
      }
    } else {
      const notification = {
        type: "error",
        message: error.message,
      };
      store.dispatch("notification/add", notification);
      return false;
    }
  }
}

export default ErrorHandler;
