<template>
  <div>
    {{ countdown }}
  </div>
</template>

<script>
export default {
  data() {
    return {
      startingTime: 458693166,
      endingTime: 789693166,
      countdown: "00:00",
      timer: null,
    };
  },
  mounted() {
    this.startCountdown();
  },
  methods: {
    startCountdown() {
      const totalMilliseconds = this.endingTime - this.startingTime;

      this.timer = setInterval(() => {
        let remainingMilliseconds = this.endingTime - Date.now();

        if (remainingMilliseconds <= 0) {
          clearInterval(this.timer);
          this.countdown = "00:00";
        } else {
          const minutes = Math.floor((remainingMilliseconds / 1000 / 60) % 60);
          const seconds = Math.floor((remainingMilliseconds / 1000) % 60);

          this.countdown = `${this.formatTime(minutes)}:${this.formatTime(
            seconds
          )}`;
        }
      }, 1000);
    },
    formatTime(time) {
      return time < 10 ? `0${time}` : time;
    },
  },
  beforeDestroy() {
    clearInterval(this.timer);
  },
};
</script>
