<template>
  <div id="Myfont" class="bg-darkbg h-screen">
    <!-- @click="openSearchTicketModal()" -->

    <BaseModal
      modalClass="w-2/4"
      :visible="searchOpenModalStatus"
      :isClosable="false"
    >
      <SearchTicketModal
    /></BaseModal>
    <CashierNavBar class="z-50" />
    <RouterView />
  </div>
</template>
<script setup>
// import { RouterLink, RouterView } from "vue-router";
</script>
<script>
import { mapGetters } from "vuex";
import SearchTicketModal from "@/components/Modal/SearchTicketModal.vue";
import CashierNavBar from "../../components/CashierNavBar.vue";

export default {
  components: {
    CashierNavBar,
    SearchTicketModal,
  },
  data() {
    return {
      // searchOpenModalStatus: false,
    };
  },
  created() {
    this.setPrinterSettingToVuex();
  },
  computed: {
    ...mapGetters("modals", ["searchOpenModalStatus"]),
  },
  methods: {
    setPrinterSettingToVuex() {
      const code = localStorage.getItem("CodeSetting");
      this.$store.state.cashier.codeSetting = code;
    },
    openSearchTicketModal() {
      this.$store.dispatch("modals/modal", {
        stat: "searchOpenModal",
        status: !this.searchOpenModalStatus,
      });
    },
    //  localStorage.setItem("CodeSetting", this.CodeSetting);
    // this.$store.state.cashier.codeSetting = this.CodeSetting;
  },
};
</script>

<style>
#Myfont {
  font-family: PTSans;
  /* src: local('mons'), url('./assets/Fonts/') format('truetype');s */
}
@font-face {
  font-family: "PTSans";
  src: local("PTSans"),
    url("../../assets/Fonts/Montserrat-Regular.ttf") format("truetype");
  font-weight: 100;
  font-size: xx-large;
}
</style>
