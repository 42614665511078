<template>
  <div v-if="warningZone">Auto Logout</div>
</template>

<script>
export default {
  data() {
    return {
      events: ["mousemove", "click", "keypress", "mousedown", "scroll", "load"],
      // warningTimer: null,
      timeOut: null,
      // logOutTimer: null,
      warningZone: false,
      loggedIn: false,
    };
  },
  created() {
    document.addEventListener("mousemove", this.setTimer());
    document.addEventListener("keypress", this.setTimer());
    document.addEventListener("mousedown", this.setTimer());
    document.addEventListener("scroll", this.setTimer());
    document.addEventListener("click", this.setTimer());
  },
  mounted() {
    this.setTimer();
    // this.events.forEach((event) => {
    //   window.addEventListener(event, this.resetTimer());
    // });
    // this.setTimers();
    // window.removeEventListener("mousemove", this.setTimers());
    // window.removeEventListener("keypress", this.setTimers());
    // window.removeEventListener("mousedown", this.setTimers());
    // window.removeEventListener("scroll", this.setTimers());
    // window.removeEventListener("click", this.setTimers());
  },
  destroyed() {
    // this.events.forEach((event) => {
    //   window.removeEventListener(event, this.resetTimer());
    // });
    document.removeEventListener("mousemove", this.resetTimer());
    document.removeEventListener("keypress", this.resetTimer());
    document.removeEventListener("mousedown", this.resetTimer());
    document.removeEventListener("scroll", this.resetTimer());
    document.removeEventListener("click", this.resetTimer());
    // window.removeEventListener('load', this.resetTimer());
    // this.resetTimer();
  },
  methods: {
    setTimer() {
      this.timeOut = setTimeout(() => {
        this.logout();
      }, 6 * 10000);
    },
    // setTimers() {
    //   this.warningTimer = setTimeout(this.warningMessage, 4 * 1000);
    //   this.logOutTimer = setTimeout(this.logout, 8 * 1000);
    //   this.warningZone = false;
    // },
    warningMessage() {
      this.warningZone = true;
    },
    resetTimer() {
      clearTimeout(this.timeOut);
      // clearTimeout(this.warningTimer),
      //   clearTimeout(this.logOutTimer),
      this.setTimer();
    },
    logout() {
      localStorage.clear();
      this.$store.dispatch("Authentication/logOut");
      this.$router.push({
        path: "/login",
      });
    },
  },
};
</script>

<style></style>
