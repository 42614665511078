<template>
  <div
    class="print:w-printerSize print:left-0 print:top-0 print: print:p-0 print:m-0"
  >
    <div class="flex justify-center py-24" v-if="spinner"><spinner /></div>
    <div
      v-if="!spinner && !noConnection"
      class="m-5 text-white print:hidden space-x-3"
    >
      <div class="space-y-5">
        <div class="h-auto bg-darkcard rounded-lg p-4">
          <div class="flex justify-between">
            <p class="text-white">Todays Report</p>
            <div>
              <BaseButton
                v-if="animateTotalGrossIncome !== 0"
                buttonClass="bg-blue-700 text-sm m-2 print:hidden py-2 px-2 rounded-md"
                @click="closeReport"
                >Print Report</BaseButton
              >
            </div>
          </div>

          <div class="">
            <apexchart
              ref="chart"
              class="text-text z-40"
              width="100%"
              height="300"
              type="area"
              :options="options"
              :series="series"
            ></apexchart>
          </div>
        </div>
        <div
          class="text flex justify-around p-5 text-sm rounded-lg bg-darkcard"
        >
          <div
            class="bg-darkbg space-y-2 text-base rounded-lg p-3 w-48 text-center"
          >
            <div>Total Tickets Sold</div>
            <div class="text-indigo-500 space-x-3 text-center text-xl">
              <div>{{ animateTotalTickets.toLocaleString() }}</div>
            </div>
          </div>

          <div
            class="bg-darkbg space-y-2 text-base rounded-lg p-3 w-48 text-center"
          >
            <div>Total Gross income</div>
            <div class="text-yellow-500 space-x-3 text-center text-xl">
              <div>{{ animateTotalGrossIncome.toLocaleString() }}</div>
            </div>
          </div>
          <div
            class="bg-darkbg space-y-2 text-base rounded-lg p-3 w-48 text-center"
          >
            <div>Total Payouts</div>
            <div class="text-red-400 space-x-3 text-center text-xl">
              <div>{{ animateTotalPayouts.toLocaleString() }}</div>
            </div>
          </div>
          <div
            class="bg-darkbg space-y-2 text-base rounded-lg p-3 w-48 text-center"
          >
            <div>Total Profit</div>
            <div
              :class="{
                'text-redo': animateTotalProfit < 0,
                'text-green-500': animateTotalProfit > 0,
              }"
              class="space-x-3 text-center text-xl"
            >
              <div>{{ animateTotalProfit.toLocaleString() }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="flex print:hidden justify-center py-4" v-if="noConnection">
      <network-issue />
    </div>
    <!-- print -->
    <div class="hidden print:block print:text-start">
      <div class="print:block hidden text-black">
        <div class="mb-3">
          <div class="text-text text-lg flex justify-center">
            <img
              class="h-14 w-14 mt-3"
              src="../../../public/logo.svg"
              alt=""
              srcset=""
            />
          </div>
          <div class="text-center text-2xl text-black font-extrabold">
            KenoPro
          </div>
        </div>
        <div class="text-center text-xl text-black">Report Detail's</div>
        <div>
          <div class="flex text-center space-x-3">
            <div>cashier Name:</div>
            <div>{{ cashierInfo.employeeName }}</div>
          </div>
          <div class="flex text-center space-x-3">
            <div>Report Time:</div>
            <div>{{ currentTime | date }}</div>
          </div>
          <div class="flex text-center space-x-3">
            <div>Total Tickets:</div>
            <div>{{ animateTotalTickets.toLocaleString() }}</div>
          </div>
          <div class="flex text-center space-x-3">
            <div>Total Gross Income:</div>
            <div>{{ animateTotalGrossIncome.toLocaleString() }} birr</div>
          </div>
          <div class="flex text-center space-x-3">
            <div>Total Payout:</div>
            <div>{{ animateTotalPayouts.toLocaleString() }} birr</div>
          </div>
          <div class="flex text-center space-x-3">
            <div>Total Profit:</div>
            <div>{{ animateTotalProfit.toLocaleString() }} birr</div>
          </div>
          <div class="flex text-center space-x-3">
            <div>Total Amount to be Reported:</div>
            <div>{{ animateTotalProfit.toLocaleString() }} birr</div>
          </div>
          <div class="flex text-center space-x-3">
            <div>please verify your ticket with your branch manager!</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import spinner from "@/components/spinner.vue";
import NetworkIssue from "@/Error/NetworkIssue.vue";
import { mapState } from "vuex";
import gsap from "gsap";
export default {
  components: { spinner, NetworkIssue },
  data() {
    return {
      spinner: false,
      noConnection: false,
      currentTime: 0,
      cashierInfo: [],
      totalGrossIncome: 0,
      animateTotalGrossIncome: (0).toFixed(0),
      totalPayouts: 0,
      animateTotalPayouts: (0).toFixed(0),
      totalTickets: 0,
      animateTotalTickets: (0).toFixed(0),
      totalProfit: 0,
      animateTotalProfit: (0).toFixed(0),
      options: {
        chart: {
          id: "vuechart-example",
        },
        xaxis: {
          categories: ["0", "ticket Sale", "Total income", "Payout", "Profit"],
        },
        theme: {
          mode: "dark",
          palette: "palette6",
          monochrome: {
            enabled: false,
            color: "#255aee",
            shadeTo: "dark",
            shadeIntensity: 1,
          },
        },
        stroke: {
          curve: "smooth",
        },
        markers: {
          size: 3,
        },
      },
      options2: {
        chart: {
          id: "vuechart-example",
        },
        xaxis: {
          categories: ["00:00", "now"],
        },
        theme: {
          mode: "dark",
          palette: "palette6",
        },
      },

      series: [
        {
          data: [],
        },
        // {
        //   name: "Ticket sale",
        //   data: [89, 54, 65],
        // },
      ],
    };
  },
  watch: {
    totalGrossIncome(newValue) {
      gsap.to(this.$data, {
        duration: 1,
        ease: "circ.out",
        animateTotalGrossIncome: newValue,
      });
    },
    totalPayouts(newValue) {
      gsap.to(this.$data, {
        duration: 1,
        ease: "circ.out",
        animateTotalPayouts: newValue,
      });
    },
    totalTickets(newValue) {
      gsap.to(this.$data, {
        duration: 1,
        ease: "circ.out",
        animateTotalTickets: newValue,
      });
    },
    totalProfit(newValue) {
      gsap.to(this.$data, {
        duration: 1,
        ease: "circ.out",
        animateTotalProfit: newValue,
      });
    },
  },
  created() {
    const user = localStorage.getItem("user");
    if (user) {
      this.cashierInfo = JSON.parse(user);
    }
    // this.spinner = true;
    // this.$store.dispatch("cashier/getTodayReports").then((response) => {
    //   if (response) {
    //     this.totalGrossIncome = response.data.totalPlacedMoney;
    //     this.totalPayouts = response.data.totalPayoutMoney;
    //     this.totalProfit = response.data.totalProfit;
    //     this.totalTickets = response.data.result;

    //     this.$store.dispatch("Authentication/getMe").then((res) => {
    //       if (res) {
    //         console.log("res: ", res);
    //         this.totalGrossIncome = response.data.totalPlacedMoney;
    //         this.totalPayouts = res.totalPayoutMoney;
    //         this.totalProfit =
    //           Number(response.data.totalProfit) - Number(res.totalPayoutMoney);
    //         this.totalTickets = response.data.result;
    //         this.series = [
    //           {
    //             data: [
    //               "0",
    //               response.data.result,
    //               this.totalGrossIncome,
    //               this.totalPayouts,
    //               this.totalProfit,
    //             ],
    //           },
    //         ];
    //         this.spinner = false;
    //       }
    //     });
    //   } else {
    //     this.spinner = false;
    //     this.noConnection = true;
    //   }
    // });
    this.$store.dispatch("Authentication/getMe").then((res) => {
      if (res) {
        console.log("res: ", res);
        this.totalGrossIncome = res.unreportedTotalPlacedMoney;
        this.totalPayouts = res.unreportedTotalPayoutMoney;
        this.totalProfit =
          Number(res.unreportedTotalPlacedMoney) -
          Number(res.unreportedTotalPayoutMoney);
        this.totalTickets = res.unreportedTotalTickets;
        this.series = [
          {
            data: [
              "0",
              this.totalTickets,
              this.totalGrossIncome,
              this.totalPayouts,
              this.totalProfit,
            ],
          },
        ];
        this.spinner = false;
      }
    });
  },
  mounted() {},
  computed: {
    ...mapState("cashier", ["report"]),
  },
  methods: {
    closeReport() {
      const currentDate = new Date();
      const currentTime = currentDate;

      this.currentTime = currentTime;
      this.$store.dispatch("Authentication/getMe").then((res) => {
        if (res) {
          // console.log("res: ", res);
          this.totalGrossIncome = res.unreportedTotalPlacedMoney;
          this.totalPayouts = res.unreportedTotalPayoutMoney;
          this.totalProfit =
            Number(res.unreportedTotalPlacedMoney) -
            Number(res.unreportedTotalPayoutMoney);
          this.totalTickets = res.unreportedTotalTickets;
          this.series = [
            {
              data: [
                "0",
                this.totalTickets,
                this.totalGrossIncome,
                this.totalPayouts,
                this.totalProfit,
              ],
            },
          ];
          window.print();
          // this.spinner = false;
        }
      });
      // Print the report with the updated time
    },

    assignDataToApexChart() {
      // console.log("se; ", this.series[0].data[0]);
    },
  },
};
</script>

<style></style>
