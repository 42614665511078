<template>
  <div id="app" class="bg-darkbg h-screen">
    <!-- <AutoLogout v-if="auth" /> -->
    <notification-container class="z-50 print:hidden" style="z-index: 100" />
    <router-view />
  </div>
</template>
<script>
import NotificationContainer from "./components/Notifications/NotificationContainer.vue";
import AutoLogout from "./components/Authentication/AutoLogout.vue";
export default {
  components: { NotificationContainer, AutoLogout },
  data() {
    return {
      auth: false,
    };
  },
  mounted() {
    this.$store.dispatch("cashier/clearPrint");
    let token = localStorage.getItem("token");
    token ? (this.auth = true) : (this.auth = false);
    // console.log("auth: ", this.auth);
  },
};
</script>

<style>
.slide-enter-active,
.slide-leave-active {
  transition: transform 0.5s ease;
}

.slide-enter,
.slide-leave-to {
  transform: translateX(100%);
}
</style>
