<template>
  <!-- 2.3.0 -->
  <div>
    <!-- <div v-if="spinner" class="flex justify-center py-16"><spinner /></div> -->
    <div v-if="!spinner">
      <div>
        <div>
          <div class="hidden print:block">
            <!-- 56 -->
            <!-- <div id="small" v-if="printerSetting == '56P'">
              <Printer56P ref="printer56" />
            </div> -->
            <div id="big-printer">
              <print80 ref="printer80" />
            </div>
            <!-- 80 -->
          </div>
        </div>
      </div>
      <div class="">
        <BaseModal modalClass="md:w-3/4 w-11/12" :visible="instructionStatus">
          <Instructions
        /></BaseModal>
        <!-- nav bar creating ticket -->
        <div
          class="print:hidden grid grid-cols-8 mt-2 px-4 p-1 space-x-4 bg-darkcard"
        >
          <div class="flex col-span-7">
            <div class="flex space-x-2 w-1/4">
              <div class="text-white">Spots</div>
              <div>
                <input
                  type="number"
                  @keypress.enter="chooseSpotEnter()"
                  ref="spots"
                  v-model="selectedNumber"
                  :class="{
                    'border-redo border   bg-darkbg outline-none w-full text-redo  p-1 rounded':
                      invalidNumber || numberNotSelected,
                    ' bg-darkbg outline-none w-full text-white  p-1 rounded':
                      !invalidNumber || !numberNotSelected,
                  }"
                />
                <div v-if="invalidNumber" class="text-redo text-sm">
                  number is not between 1 and 80
                </div>
                <div v-if="numberNotSelected" class="text-redo text-sm">
                  at least select one number!
                </div>
              </div>
            </div>
            <div class="w-1/4 flex space-x-2">
              <div class="text-white">Cash</div>
              <div>
                <BaseInput
                  type="number"
                  :inputClass="{
                    'border-redo border text-redo  bg-darkbg outline-none w-full   p-1 rounded':
                      selectCash || invalidCash,
                    ' bg-darkbg outline-none w-full text-white  p-1 rounded':
                      !selectCash || !invalidCash,
                  }"
                  @keypress.enter="AddOrderFromEnter()"
                  inputClass="bg-darkbg outline-none w-full text-white  p-1 rounded "
                  v-model="price"
                />
                <div v-if="selectCash" class="text-redo text-sm">
                  enter cash!
                </div>
                <div v-if="invalidCash" class="text-redo text-sm font bold">
                  Minimum money is 20!
                </div>
              </div>
            </div>
            <div class="mx-5 flex space-x-24">
              <button
                :disabled="ticket.game.length == 0 || createTicketSent"
                class="text-center w-40 flex justify-center text-white bg-text text-sm p-2 px-4 rounded-md"
                :class="{
                  'cursor-not-allowed': ticket.game.length == 0,
                }"
                @click="createTicket()"
                tabindex="0"
                @keypress.space="createTicket()"
              >
                <BaseSpinner v-if="createTicketSent" />
                <div v-if="!createTicketSent">Create Bet</div>
              </button>
              <!-- <div class="w-80">
                <BaseButton
                  buttonClass="text-center mt-1 w-3/5 text-white bg-red-500 text-xs p-2 px-4 rounded-md"
                  @click="openSearchTicketModal()"
                  ><i class="fa fa-search fa-1x"></i> Search Ticket</BaseButton
                >
              </div> -->
            </div>
          </div>
          <div class="flex">
            <BaseButton
              class="w-80 text-center text-white bg-yellow-500 text-xs p-2 px-4 rounded-md"
              @click="openInstructions()"
              >Read Instructions</BaseButton
            >
          </div>
        </div>

        <div class="flex mt-3 print:hidden">
          <div class="space-y-2 w-3/5">
            <!-- first 40 -->
            <div class="bg-darkcard rounded-md mx-3 space-y-3 px-4 py-2">
              <!-- <div class="grid grid-cols-10 gap-2 justify-between rounded-md">
                <div v-for="(index, i) in numberList" :key="i" class="">
                  <div
                    @click="chooseSpot(index)"
                    class="w-14 px-2 p-3 cursor-pointer bg-darkbg text-center font-bold text-xl text-white rounded-md"
                    :class="{ 'bg-text text-white': forStyle.includes(index) }"
                  >
                    {{ index }}
                  </div>
                </div>
              </div> -->

              <div class="grid grid-cols-10 gap-1 justify-between rounded-md">
                <div v-for="(index, i) in numberList" :key="i" class="">
                  <div
                    @click="chooseSpot(index)"
                    class="w-18 px-2 p-4 cursor-pointer bg-darkbg text-center font-bold text-xl text-white rounded-md"
                    :class="{ 'bg-text text-white': forStyle.includes(index) }"
                  >
                    {{ index }}
                  </div>
                </div>
              </div>
            </div>

            <!-- after 40 -->
            <div class="bg-darkcard rounded-md mx-4 px-4 py-2 mt-8 space-y-3">
              <div class="grid grid-cols-10 gap-1 justify-between rounded-md">
                <div v-for="(index, i) in numberList2" :key="i" class="">
                  <div
                    @click="chooseSpot(index)"
                    class="w-18 px-2 p-4 cursor-pointer bg-darkbg text-center font-bold text-xl text-white rounded-md"
                    :class="{ 'bg-text text-white': forStyle.includes(index) }"
                  >
                    {{ index }}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- add price -->
          <div style="" class="bg-darkcard w-32 text-white text-center">
            <div class="text-text">Prices</div>
            <div class="space-y-3">
              <BaseInput inputClass="w-11/12 bg-darkbg" v-model="price" />
              <div v-for="(i, index) in priceList" :key="index">
                <p
                  @click="addPrice(i)"
                  @keypress.enter="addPrice()"
                  :class="{ 'bg-green-500 text-white ': i == price }"
                  class="p-1 cursor-pointer mx-3 rounded-md text-green-500 bg-darkbg"
                >
                  {{ i }}
                </p>
              </div>

              <button
                v-if="!editCash"
                :disabled="
                  price == '' ||
                  ticket.game.length >= 8 ||
                  spotHolder.length === 0
                "
                :class="{
                  'cursor-not-allowed':
                    price == '' ||
                    ticket.game.length >= 8 ||
                    spotHolder.length === 0,
                }"
                @click="AddOrder"
                class="text-center w-full text-white bg-green-500 text-base py-2 px-4"
              >
                Add
              </button>
              <button
                v-if="editCash"
                @click="addGameAfterEdit()"
                class="text-center text-white w-full bg-yellow-500 text-sm p-1 px-4"
              >
                Edit
              </button>
            </div>
          </div>
          <!-- selected numbers display -->
          <div class="space-y-2 mx-4 w-80 bg-darkcard text-gray-300">
            <div class="flex justify-between">
              <p class="w-full text-text mx-3">Games</p>
              <p
                @click="RemoveAllBets()"
                class="w-full text-redo text-right mt-1 font-bold mr-3 cursor-pointer text-xs"
              >
                Clear All
              </p>
            </div>
            <div class="w-80">
              <div
                v-for="(spo, index) in ticket.game"
                :key="index"
                class="bg-darkbg mx-2 mt-2 rounded-lg p-1 text-sm"
              >
                <div class="flex">
                  <p class="">Spots:</p>
                  <div class="flex">
                    <!-- {{ spo.selectedNumbers }} -->
                    <p
                      :class="{
                        ' mr-1 ': spo.selectedNumbers.length > 7,
                        ' mr-2': spo.selectedNumbers.length < 7,
                      }"
                      v-for="(s, index) in spo.selectedNumbers"
                      :key="index"
                    >
                      {{ s.number }},
                    </p>
                  </div>
                </div>

                <div class="flex justify-between">
                  <div class="flex space-x-2">
                    <div>Price:</div>
                    <p>{{ spo.placedMoney }}</p>
                  </div>
                  <div class="flex space-x-2">
                    <p
                      v-if="!EditMyCash[index]"
                      class="cursor-pointer fa fa-edit"
                      @click="EditCash(index)"
                    ></p>
                    <p
                      v-if="EditMyCash[index]"
                      class="cursor-pointer text-black bg-yellow-500 font-mono"
                      @click="cancelEdit(index)"
                    >
                      Cancel Edit
                    </p>
                    <p
                      class="cursor-pointer text-redo fa fa-trash"
                      @click="removeBet(index)"
                    ></p>
                  </div>
                </div>
              </div>
            </div>
            <div class="flex justify-center">
              <base-spinner2 v-if="ticketAddingLoader" />
            </div>

            <div class="mx-5">
              <button
                v-if="!ticketAddingLoader"
                :disabled="ticket.game.length == 0 || createTicketSent"
                :class="{
                  'cursor-not-allowed': ticket.game.length == 0,
                }"
                @click="createTicket()"
                class="text-center flex justify-center text-white w-full bg-text text-sm p-2 px-4 rounded-md"
              >
                <BaseSpinner v-if="createTicketSent" />
                <div v-if="!createTicketSent">Create Bet</div>
              </button>
            </div>
          </div>
          <!-- total Cash indicator and last 5 tickets -->
          <div class="space-y-2">
            <div class="bg-darkcard p-2 h-24 w-56">
              <div class="bg-darkbg p-1 rounded-lg">
                <p class="text-text">Total Report</p>
                <div class="text-white flex text-sm">
                  <p class="text-xs">Total Cash:</p>
                  <p class="text-text ml-3">{{ totalPrice }}</p>
                </div>
                <div class="text-white flex text-xs">
                  <p class="text-xs">Games:</p>
                  <p class="text-text ml-3">{{ ticket.game.length }}</p>
                </div>
              </div>
            </div>
            <div class="bg-darkcard p-2 h-fit w-56">
              <p class="text-text">Latest Recalls</p>
              <div class="flex justify-center w-full mb-1">
                <input
                  @keypress.enter="searchTicket()"
                  ref="idInput"
                  label=""
                  :class="{ 'border border-redo': noInput }"
                  class="bg-darkbg text-white text-sm px-1 outline-none w-40 p-1 rounded"
                  v-model="id"
                />

                <div class="flex justify-center space-x-2 m-auto p-auto">
                  <button
                    v-if="!noTicketFound"
                    class="cursor-pointer w-full rounded-md px-2 text-sm text-white p-1 flex justify-center space-x-3 bg-text"
                    @click="searchTicket()"
                  >
                    <BaseSpinner v-if="searchRequestSent" class="" /><i
                      v-if="!searchRequestSent"
                      class="fa fa-search text-white"
                    ></i>
                  </button>
                  <div>
                    <p
                      v-if="noTicketFound"
                      :class="{ 'cursor-not-allowed': ticketAddingLoader }"
                      @click="clearSearchHistory()"
                      class="fa fa-times hover:text-white hover:bg-redo p-1 cursor-pointer rounded-md px-2 text-redo"
                    ></p>
                  </div>
                </div>
              </div>
              <div
                v-if="noTicketFound"
                class="p-8 gap-5 text-redo bg-darkbg mt-2"
              >
                No ticket found!
              </div>
              <div
                v-if="search == false && !noTicketFound"
                class="bg-darkbg p-1 rounded-lg"
              >
                <div
                  v-for="(i, index) in allTickets"
                  :key="index"
                  class="flex justify-between space-x-1 w-full"
                >
                  <div class="text-white flex text-sm">
                    <p class="text-xs">Id:</p>
                    <p class="text-text ml-1">{{ i.customId }}</p>
                  </div>
                  <div class="flex justify-start">
                    <div>
                      <BaseButton
                        :disabled="ticketAddingLoader"
                        :class="{ 'cursor-not-allowed': ticketAddingLoader }"
                        @click="EditTicketFromCreated(i.customId)"
                        class="ml-2 hover:text-white hover:bg-green-500 p-1 cursor-pointer rounded-md px-1 text-green-500"
                        ><i class="fa fa-edit"></i
                      ></BaseButton>
                    </div>
                    <div>
                      <BaseButton
                        :disabled="ticketAddingLoader"
                        :class="{ 'cursor-not-allowed': ticketAddingLoader }"
                        @click="createTicketAgain(i.customId)"
                        class="hover:text-white hover:bg-yellow-500 p-1 cursor-pointer rounded-md px-1 text-yellow-500"
                        ><i class="fa fa-print"></i
                      ></BaseButton>
                    </div>
                    <div v-if="i.gameId.status == 'pending'">
                      <BaseButton
                        :disabled="rejectTicketSent"
                        :class="{ 'cursor-not-allowed': rejectTicketSent }"
                        @click="RejectBet(i)"
                        class="hover:text-white hover:bg-red-500 p-1 cursor-pointer rounded-md px-1 text-redo"
                        ><i class="fa fa-times"></i
                      ></BaseButton>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import io from "socket.io-client";
// import printer from "vue-printer";
// import Printer56P from "@/components/Printer56P.vue";
import Print80 from "@/components/Print.vue";
// import VueHtmlToPaper from "vue-html-to-paper";

import { mapGetters, mapState } from "vuex";
import spinner from "@/components/spinner.vue";
import Instructions from "../../components/Modal/Instructions.vue";
import SearchTicketModal from "@/components/Modal/SearchTicketModal.vue";
export default {
  components: {
    Print80,
    spinner,
    // printer,
    // Printer56P,
    Instructions,
    SearchTicketModal,
    // VueHtmlToPaper,
  },
  data() {
    return {
      CodeType: false,
      search: false,
      spinner: false,
      editCash: false,
      editingIndex: null,
      // allTickets: [],
      ticketAddingLoader: false,
      EditMyCash: {},
      selectedNumber: "",
      numberNotSelected: false,
      rejectTicketSent: false,
      printerSetting: "",
      EditedPrice: "",
      price: "",
      totalSpotHolder: [],
      spotHolder: [],
      printerSettingSelected: false,
      invalidNumber: false,
      selectCash: false,
      invalidCash: false,
      barCode: [],
      createTicketSent: false,
      dif: 0,
      invalidCashInEdit: false,
      priceList: [20, 30, 40, 50, 60, 70, 80, 90, 100, 200],
      URL: [],
      CodeSetting: "",
      searchRequestSent: false,
      noTicketFound: false,
      noInput: false,
      id: "",
      // forStyle: [],
    };
  },
  created() {
    // this.$store.dispatch("cashier/clearPrint");
    this.URL = process.env.VUE_APP_API_BASE_URL;
    // this.setPrinterSettingToLS();
    // this.setPrinterSettingToVuex();
    this.spinner = true;
    this.fetchTickets();
  },
  computed: {
    ...mapState("cashier", ["ticket"]),
    ...mapState("cashier", ["allTickets"]),
    ...mapState("cashier", ["totalPrice"]),
    ...mapGetters("modals", ["printerStatus"]),
    ...mapGetters("modals", ["printerStatus56P"]),
    ...mapGetters("modals", ["instructionStatus"]),
    ...mapGetters("modals", ["searchOpenModalStatus"]),

    forStyle() {
      let tmpArray = [];
      this.spotHolder.forEach((i) => {
        tmpArray.push(Number(i.number));
      });
      return tmpArray;
    },
    numberList() {
      let tmpArray = [];
      for (let i = 1; i <= 40; i++) {
        tmpArray.push(i);
      }
      return tmpArray;
    },
    numberList2() {
      let tmpArray = [];
      for (let i = 41; i <= 80; i++) {
        tmpArray.push(i);
      }
      return tmpArray;
    },

    //  ...mapState('leagues', ['leagues']),
  },
  mounted() {},
  watch: {},

  methods: {
    chooseSpot(i) {
      let spotToRemove = this.spotHolder.findIndex(
        (exist) => Number(i) === Number(exist.number)
      );
      if (spotToRemove >= 0) {
        this.spotHolder.splice(spotToRemove, 1);
      } else {
        if (this.spotHolder.length < 10) {
          this.spotHolder.push({
            number: i,
          });
        } else {
        }
      }
      this.selectedNumber = "";
    },
    setPrinterSettingToVuex() {
      localStorage.setItem("printerSetting", this.printerSetting);
      const code = localStorage.getItem("CodeSetting");
      this.$store.state.cashier.codeSetting = code;
    },
    setPrinterSettingToLS() {
      const code = "Bar Code";
      localStorage.setItem("CodeSetting", code);
      this.printerSetting = "80P";
      localStorage.setItem("printerSetting", this.printerSetting);
      this.$store.state.cashier.codeSetting = code;
      // console.log("printerSetting: ", printer);
    },
    chooseSpotEnter() {
      if (this.selectedNumber > 0 && this.selectedNumber < 81) {
        this.numberNotSelected = false;
        this.invalidNumber = false;
        let spotToRemove = this.spotHolder.findIndex(
          (exist) => Number(this.selectedNumber) === Number(exist.number)
        );
        if (spotToRemove >= 0) {
          // console.log("it exists");
          this.spotHolder.splice(spotToRemove, 1);
        } else {
          if (this.spotHolder.length < 8) {
            this.spotHolder.push({
              number: this.selectedNumber,
            });
            // console.log("spotHolder after enter: ", this.spotHolder);
          } else {
            // console.log("capacity");
          }
        }
        this.selectedNumber = "";
        this.$nextTick(() => {
          this.$refs.spots.focus();
        });
      } else {
        this.invalidNumber = true;
        this.$nextTick(() => {
          this.$refs.spots.focus();
        });
      }
      // this.selectedNumber = i
    },
    AddOrder() {
      this.$store
        .dispatch("cashier/addGame", {
          selectedNumbers: this.spotHolder,
          placedMoney: this.price,
          // customId: this.gameId,
        })
        .then((response) => {
          if (response) {
            this.spotHolder = [];
            this.price = "";
          }
        });
    },
    addGameAfterEdit() {
      if (this.spotHolder.length !== 0) {
        this.numberNotSelected = false;
        if (this.price !== "" && this.price !== 0) {
          this.selectCash = false;
          if (Number(this.price) >= 20) {
            this.invalidCash = false;
            this.$store
              .dispatch("cashier/addGameAfterEdit", {
                selectedNumbers: this.spotHolder,
                placedMoney: this.price,
                index: this.editingIndex,
              })
              .then((response) => {
                if (response) {
                  this.EditMyCash[this.editingIndex] = false;
                  this.editCash = false;
                  this.spotHolder = [];
                  this.price = "";
                }
              });
          } else {
            this.invalidCash = true;
          }
        } else {
          this.selectCash = true;
        }
      } else {
        this.numberNotSelected = true;
        this.$nextTick(() => {
          this.$refs.spots.focus();
        });
      }
    },
    EditCash(index) {
      this.editCash = true;
      this.spotHolder = [];
      this.editingIndex = index;
      this.EditMyCash = {};
      this.EditMyCash[this.editingIndex] = true;
      let selected = this.$store.state.cashier.ticket.game[index];
      this.EditMyCash[index] = true;
      let numbersArray = selected.selectedNumbers;
      this.price = selected.placedMoney;
      numbersArray.forEach((number) => {
        // console.log("number", number.number);
        this.chooseSpot(Number(number.number));
      });

      this.$set(this.EditMyCash, index, true);
    },
    stopEdit() {
      this.editCash = false;
      this.spotToRemove = [];
      this.price = "";
    },
    focusEditPrice() {
      this.$nextTick(() => {
        if (this.$refs.EditPriceFocus) {
          this.$refs.EditPriceFocus.focus();
        }
      });
    },
    removeBet(index) {
      // console.log("here: ", index);
      this.$store
        .dispatch("cashier/RemoveBet", index)
        .then((response) => {
          if (response) {
            this.EditMyCash[index] = false;
            this.editCash = false;
            this.spotHolder = [];
            this.price = "";
            this.clearAllErrorSigns();
          }
        })
        .catch((error) => {
          // console.log("error:", error);
        });
    },
    cancelEdit(index) {
      this.EditMyCash = {};
      this.clearAllErrorSigns();
      this.EditMyCash[index] = false;
      this.editCash = false;
      this.spotHolder = [];
      this.price = "";
    },
    RemoveAllBets() {
      this.$store.dispatch("cashier/RemoveAllBets");
      this.clearAllErrorSigns();
    },
    clearSearchHistory() {
      this.clearAllErrorSigns();
      // this.getAllTickets();
    },
    clearAllErrorSigns() {
      this.id = "";
      this.search = false;
      this.noInput = false;
      this.noTicketFound = false;
      this.numberNotSelected = false;
      this.selectCash = false;
      this.invalidCash = false;
    },
    searchTicket() {
      if (this.id) {
        this.noInput = false;
        this.noTicketFound = false;
        this.searchRequestSent = true;
        this.$store
          .dispatch("cashier/searchTicketUsingBarCodeInDashboard", this.id)
          .then((res) => {
            if (res) {
              this.searchRequestSent = false;
              this.noTicketFound = false;
              this.barCode = res[0];
              this.search = true;
              if (!res[0]) {
                this.barCode = [];
                this.search = false;
                // console.log("here", this.barCode);
                this.noTicketFound = true;
              }
            } else {
              this.noTicketFound = true;
            }
            this.searchRequestSent = false;
          });
      } else {
        this.noInput = true;
      }

      // console.log("id: ", this.id);
    },
    fetchTickets() {
      this.$store
        .dispatch("cashier/getAllTickets", {
          perPage: 10,
          page: 1,
        })
        .then((response) => {
          if (response) {
            // this.allTickets = response;

            this.spinner = false;
            if (!this.spinner) {
              this.$nextTick(() => {
                this.$refs.spots.focus();
              });
            }
          } else {
            this.spinner = false;
          }
        })
        .catch((error) => {
          this.spinner = false;
        });
    },
    RejectBet(tick) {
      if (tick.id) {
        this.ticketAddingLoader = true;
        // this.rejectTicketSent = true;
        this.$store
          .dispatch("cashier/RejectBet", tick.id)
          .then((response) => {
            if (response) {
              this.fetchTickets();
              const alert = {
                type: "success",
                message: "Ticket Canceled!",
              };
              this.$store.dispatch("notification/add", alert);
              // this.rejectTicketSent = false;
              this.ticketAddingLoader = false;
            } else {
              this.ticketAddingLoader = false;
              this.fetchTickets();
            }
          })
          .catch((error) => {
            this.ticketAddingLoader = false;
          });
      }
    },
    AddOrderFromEnter() {
      if (this.spotHolder.length !== 0) {
        this.numberNotSelected = false;
        if (this.price !== "" && this.price !== 0) {
          this.selectCash = false;
          if (Number(this.price) >= 20) {
            this.invalidCash = false;
            this.$store
              .dispatch("cashier/addGame", {
                selectedNumbers: this.spotHolder,
                placedMoney: Number(this.price),
                // customId: this.gameId,
              })
              .then((response) => {
                if (response) {
                  this.spotHolder = [];
                  this.price = "";
                  this.$refs.spots.focus();
                }
              });
          } else {
            this.invalidCash = true;
          }
        } else {
          this.selectCash = true;
        }
      } else {
        this.numberNotSelected = true;
        this.$nextTick(() => {
          this.$refs.spots.focus();
        });
      }
    },
    async createTicket() {
      let gameId = localStorage.getItem("gameIdforCashier");
      0;
      this.createTicketSent = true;
      await this.$store
        .dispatch("cashier/createTicket", gameId)
        .then((response) => {
          if (response) {
            this.getAllTickets();
            this.id = "";
            this.search = false;
            this.noInput = false;
            this.noTicketFound = false;
            // window.print
            this.$refs.spots.focus(); // Move this statement here
          }
        });
      this.createTicketSent = false;
      // console.log('th" ', this.gameId);
    },
    async createTicketAgain(id) {
      this.printerSettingSelected = false;
      this.ticketAddingLoader = true;
      await this.$store
        .dispatch("cashier/createTicketAgain", id)
        .then((response) => {
          if (response) {
            this.ticketAddingLoader = false;
            this.getAllTickets();
          } else {
            this.ticketAddingLoader = false;
          }
        })
        .catch((error) => {
          this.ticketAddingLoader = false;
        });
    },
    getAllTickets() {
      this.$store
        .dispatch("cashier/getAllTickets", {
          perPage: 10,
          page: 1,
        })
        .then((response) => {
          if (response) {
            // this.allTickets = response;
            // this.spinner = false;
          } else {
            // this.spinner = false;
          }
        })
        .catch((error) => {
          // this.spinner = false;
        });
    },
    async setPrinterSetting() {
      this.printerSettingSelected = false;
      const clearData = await this.$store.dispatch("cashier/clearPrint");
      if (clearData) {
        localStorage.setItem("printerSetting", this.printerSetting);
      }

      this.$refs.spots.focus();
    },
    EditTicketFromCreated(id) {
      this.ticketAddingLoader = true;
      this.$store
        .dispatch("cashier/EditTicketFromCreated", id)
        .then((response) => {
          if (response) {
            this.ticketAddingLoader = false;
          } else {
            this.ticketAddingLoader = false;
          }
        })
        .catch((error) => {
          this.ticketAddingLoader = false;
        });
    },
    async setCodeSetting() {
      this.CodeType = false;
      const clearData = await this.$store.dispatch("cashier/clearPrint");
      if (clearData) {
        localStorage.setItem("CodeSetting", this.CodeSetting);
        this.$store.state.cashier.codeSetting = this.CodeSetting;
      }
      // console.log("cododdodo: ", this.$store.state.cashier.codeSetting);
      this.$refs.spots.focus();
    },
    populate() {
      this.printerSetting = localStorage.getItem(
        "printerSetting",
        printerSetting
      );
    },
    modal() {
      this.$store.dispatch("modals/modal", {
        stat: "printer",
        status: !this.printerStatus,
      });
    },
    openInstructions() {
      this.$store.dispatch("modals/modal", {
        stat: "instructions",
        status: !this.instructionStatus,
      });
    },
    // openSearchTicketModal() {
    //   this.$store.dispatch("modals/modal", {
    //     stat: "searchOpenModal",
    //     status: !this.searchOpenModalStatus,
    //   });
    // },
    closeInstructions() {
      this.$store.dispatch("modals/modal", {
        stat: "instructions",
        status: !this.instructionStatus,
      });
    },
    printer56PFun() {
      this.$store.dispatch("modals/modal", {
        stat: "printer56P",
        status: !this.printerStatus56P,
      });
    },
    addPrice(p) {
      this.price = p;
    },
    addPriceFromEnter() {
      this.price = this.price;
    },
    printPage() {
      window.print();
      this.focusInput();
      this.$store.dispatch("cashier/clearPrint");
    },
    focusInput() {
      this.$nextTick(() => {
        this.$refs.spots.focus();
      });
    },
  },
};
</script>

<style></style>
