<template>
  <div>
    <div class="print:block hidden">
      <GamePrinter :gameData="gameResult" />
    </div>
    <BaseModal
      modalClass="w-2/4"
      :visible="gameDetailsStatus"
      callBack="getAll"
    >
      <GameDetails :dataInfo="info"
    /></BaseModal>
    <div class="print:hidden">
      <div class="flex justify-center py-4" v-if="noConnection">
        <network-issue />
      </div>
      <div
        v-if="!spinner && !noConnection"
        class="bg-darkcard mx-4 my-2 text-gray-300 rounded-md p-3"
      >
        <div class="grid grid-cols-7 text-base">
          <div class="col-span-5">Games</div>

          <div class="flex space-x-3 col-span-2">
            <BaseInput
              @keypress.enter="searchGameT()"
              autocomplete="off"
              placeholder="search Game"
              type="Text"
              inputClass="px-2 bg-darkbg outline-none w-full p-1 rounded "
              v-model="search"
            />
            <BaseButton
              v-if="search"
              class="text-red-600"
              @click="getAllAfterSearch()"
              >cancel</BaseButton
            >
          </div>
        </div>
        <div class="bg-darkbg h-full my-2 rounded-xl">
          <table class="table-auto w-full text-sm">
            <thead>
              <tr class="space-x-8 text-left font-light">
                <th class="border-b border-b-gray-700 px-4 font-light py-2">
                  #
                </th>
                <th class="px-4 border-b border-b-gray-700 py-2 font-light">
                  Created At
                </th>
                <th class="px-4 border-b border-b-gray-700 py-2 font-light">
                  Custom Id
                </th>
                <th class="px-4 border-b border-b-gray-700 py-2 font-light">
                  Game Status
                </th>
                <th class="px-4 border-b border-b-gray-700 py-2 font-light">
                  Details
                </th>

                <th class="px-4 border-b border-b-gray-700 py-2 font-light">
                  Print
                </th>
                <!-- <th class="px-4 border-b border-b-gray-700 py-2 font-light">
                Print
              </th> -->
              </tr>
            </thead>
            <!-- {{
            watchedGames
          }} -->
            <tbody>
              <tr
                v-if="!spinner2 && !searchRequest"
                v-for="(tick, index) in games"
                :key="index"
                class="text-gray-400"
              >
                <td class="px-4 py-2">{{ index + 1 }}</td>
                <td class="px-4 py-2">{{ tick.createdAt | date }}</td>
                <!-- <td class="px-4 py-2">{{ tick.time }}</td> -->

                <td class="px-4 py-2">
                  <p v-if="tick.customId">{{ tick.customId }}</p>
                  <p v-if="!tick.customId">---</p>
                </td>
                <td class="px-4 py-2">{{ tick.status }}</td>
                <td @click="ModalOpen(tick)" class="px-4 py-2 cursor-pointer">
                  More
                </td>

                <td class="px-4 text-left -2 py-2">
                  <div>
                    <p
                      :class="{ 'cursor-not-allowed': printInProgress }"
                      @click="PrintGameResult(tick)"
                      class="fa fa-print hover:text-white hover:bg-yellow-500 p cursor-pointer rounded-md px-2 text-yellow-500"
                    ></p>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
          <div class="text-center">
            <base-spinner-2 class="flex justify-center" v-if="searchRequest" />
          </div>
          <!-- {{ tableBodyHeight }} -->
          <div
            style="height"
            :style="`heigh:${tableBodyHeight}`"
            v-if="spinner2"
          >
            <div class="flex justify-center py-16">
              <spinner2 class="flex justify-center" />
            </div>
          </div>
          <div
            :class="`h-${tableBodyHeight}`"
            :style="`height:${tableBodyHeight}`"
            class="text-3xl py-24 w-full text-center"
            v-if="games.length === 0"
          >
            There are no tickets available!
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import spinner from "@/components/spinner.vue";
import spinner2 from "@/components/spinner2.vue";
import NetworkIssue from "@/Error/NetworkIssue.vue";
import GamePrinter from "@/components/GamePrinter.vue";
import GameDetails from "../../components/Modal/GameDetails.vue";
export default {
  components: {
    spinner,
    NetworkIssue,
    spinner2,
    GamePrinter,
    GameDetails,
  },
  data() {
    return {
      spinner2: false,
      info: [],
      noConnection: false,
      spinner: false,
      search: "",
      game: [],
      games: [],
      gameResult: [],
      searchRequest: false,
      watchedGames: [],
      tableBodyHeight: 0,
      printInProgress: false,
    };
  },
  watch: {},
  created() {
    this.setPrinterSettingToVuex();
    this.$store.dispatch("cashier/clearPrint");
    this.spinner = true;
    this.$store
      .dispatch("cashier/getAllGames", {
        perPage: 13,
        page: this.page,
      })
      .then((response) => {
        // console.log("response:", response);
        if (response) {
          this.spinner = false;
          this.games = response;
          // this.games = response;
        } else {
          this.spinner = false;
          this.noConnection = true;
        }
      })
      .catch((err) => {
        this.spinner2 = false;
        this.networkIssue = true;
      });
    this.$store.dispatch("cashier/getCountAllTickets");
  },
  computed: {
    ...mapState("cashier", ["countAllGames"]),
    ...mapGetters("modals", ["gameDetailsStatus"]),
    letsTry() {
      let page = [];
      let pages = this.countAllGames / 13;
      for (var i = 1; i < pages + 1; i++) {
        page.push(i);
      }
      return page;
    },
    page() {
      return parseInt(this.$route.query.page) || 1;
    },
    // separate() {
    //   let tmpArray = [];
    //   if (this.games) {
    //     this.games.forEach((i) => {
    //       const inputString = i.time;
    //       const holder = inputString.split(" ");
    //       let dateHolder = holder[0].split("-");
    //       let day = dateHolder[0];
    //       let date = dateHolder[1] + "-" + dateHolder[2] + "-" + dateHolder[3];
    //       let timeTable = holder[1] + " " + holder[2];
    //       console.log("dateHolder: ", dateHolder);
    //       console.log("day: ", day);
    //       console.log("timeTable: ", timeTable);
    //       console.log("holder: ", holder);
    //       tmpArray.push({
    //         ...i,
    //         day,
    //         date,
    //         timeTable,
    //       });
    //     });
    //   }
    //   return tmpArray;
    // },
  },
  methods: {
    getAll() {
      this.$store
        .dispatch("cashier/getAllGames", {
          perPage: 13,
          page: this.page,
        })
        .then((response) => {
          // console.log("response:", response);
          if (response) {
            this.spinner = false;
            this.games = response;
            // this.games = response;
          } else {
            this.spinner = false;
            this.noConnection = true;
          }
        })
        .catch((err) => {
          this.spinner2 = false;
          this.networkIssue = true;
        });
    },
    setPrinterSettingToVuex() {
      const code = localStorage.getItem("CodeSetting");
      this.$store.state.cashier.codeSetting = code;
    },
    paginate(selectedPage) {
      this.spinner2 = true;
      this.$router.push({ query: { page: selectedPage } });
      this.$store
        .dispatch("cashier/getAllGames", {
          perPage: 13,
          page: this.page,
        })
        .then((response) => {
          if (response) {
            this.games = response;
            this.spinner2 = false;
          } else {
            this.spinner2 = false;
            this.networkIssue = true;
          }
        });
    },
    PrintGameResult(game) {
      this.$store
        .dispatch("cashier/searchGame", game.customId)
        .then((response) => {
          if (response) {
            this.printInProgress = true;

            this.gameResult = response.data.doc;
            this.gameResult.numbers_That_Won.sort((a, b) => a - b);
            // console.log("gameResult: ", this.gameResult);
            setTimeout(() => {
              this.printPage();
              this.getAll();
            }, 500);
          }
        });
      this.printInProgress = false;
      // this.gameResult = game;

      // setTimeout(() => {
      //   this.printPage();
      // }, 500);
    },
    searchGameT() {
      this.searchRequest = true;
      this.$store
        .dispatch("cashier/searchGame", this.search)
        .then((response) => {
          if (response) {
            this.searchRequest = false;
            const result = response.data.doc;
            this.games = [];
            let info = {
              createdAt: response.data.doc.createdAt,
              customId: response.data.doc.gameId,
              status: "finished",
            };
            // console.log("info: ", info);
            this.games.push(info);
            // console.log("this.games: ", this.games);
            // console.log("response: ", response);
            // this.tickets = response;
          } else {
            this.searchRequest = false;
          }
        })
        .catch((error) => {
          this.searchRequest = false;
        });
    },
    searchGame() {
      this.searchRequest = true;
      this.$store
        .dispatch("cashier/searchGame", this.search)
        .then((response) => {
          if (response) {
            this.searchRequest = false;
            const result = response.data.doc;
            this.games = [];
            this.games.push(response.data.doc);
            // console.log("this.games: ", this.games);
            // console.log("response: ", response);
            // this.tickets = response;
          } else {
            this.searchRequest = false;
          }
        })
        .catch((error) => {
          this.searchRequest = false;
        });
    },
    // searchGameFromInput() {
    //   this.searchRequest = true;

    //   this.$store
    //     .dispatch("cashier/searchGame", this.search)
    //     .then((response) => {
    //       if (response) {
    //         console.log("rrrrrrrrr: ", response);
    //         this.searchRequest = false;
    //         let res = response.doc.data;
    //         let result = {
    //           matchRegisteredDate: res.createdAt,
    //           customId: res.gameId,
    //           status: "finished",
    //         };
    //         this.games = [];
    //         this.games = response;
    //         console.log("this.gameffffs: ", this.games);
    //         // console.log("response: ", response);
    //         // this.tickets = response;
    //       } else {
    //         this.searchRequest = false;
    //       }
    //     })
    //     .catch((error) => {
    //       this.searchRequest = false;
    //     });
    // },
    getAllAfterSearch() {
      this.search = "";
      this.searchRequest = true;
      this.$store
        .dispatch("cashier/getAllGames", {
          perPage: 13,
          page: this.page,
        })
        .then((response) => {
          if (response) {
            // console.log("response: ", response);
            this.games = [];
            this.searchRequest = false;
            for (let index = 0; index < 13; index++) {
              const element = response[index];
              this.games.push(element);
            }
          } else {
            this.searchRequest = false;
          }
        })
        .catch((error) => {
          this.searchRequest = false;
        });
    },
    printPage() {
      window.print();
    },
    ModalOpen(tick) {
      this.$store
        .dispatch("cashier/searchGame", tick.customId)
        .then((response) => {
          if (response) {
            // console.log(response);
            this.info = response;
            this.Modal();
          }
        });
      // this.info = tick;
    },
    Modal() {
      this.$store.dispatch("modals/modal", {
        stat: "gameDetails",
        status: !this.gameDetailsStatus,
      });
    },
  },
};
</script>

<style></style>
