// import io from "socket.io-client";
// const socket = io("http://192.168.1.7:7072");
export const namespaced = true;
export const state = {
  countDown: false,
  gameId: 840,
  index: 0,
  tryNumbers: [],
  forStyle: [],
  luckyNumbers: [],
  currentDate: 0,
  endTime: 0,
  dif: 0,
};

export const mutations = {};

export const actions = {
  async getSocket({ state }) {
    try {
      socket.on("message", (message) => {
        state.currentDate = message.message.currentDate;
        state.luckyNumbers = message.message.randomNumberArray;
        localStorage.setItem("gameId", message.message.customId);
        // console.log("different ", state.luckyNumbers);
        state.gameId = message.message.customId;
        state.dif = message.message.nextDate - message.createdAt;
        // console.log("message: ", message);
        // console.log("dif: ", state.dif);
        // console.log("nens ", message.message.nextDate);
      });
    } catch (error) {
      // console.log("error from getSocket");
    }
  },

  // async sendLuckyNumbers({ state }) {
  //   try {
  //     state.countDown = true;
  //     for (let i = 1; i <= 20; i++) {
  //       state.tryNumbers.push(i);
  //     }
  //     // console.log('try" ', state.tryNumbers);
  //     setInterval(() => {
  //       if (!(state.index > state.tryNumbers.length)) {
  //         state.index++;
  //         state.forStyle.push(state.tryNumbers[state.index]);
  //       } else {
  //       }
  //     }, 3000);
  //   } catch (error) {
  //     console.log("errortt");
  //   }
  // },
  async sendLuckyNumbers({ state }) {
    try {
      if (state) {
        setInterval(() => {
          // !(state.index > state.luckyNumbers.length)
          if (!(state.index > state.luckyNumbers.length)) {
            state.index++;
            state.forStyle.push(state.luckyNumbers[state.index]);
          }
        }, 3000);
      } else {
        console.log("timer not finished");
      }
    } catch (error) {
      console.log("error");
    }
  },
  // async sendLuckyNumbers(state) {
  //   try {
  //     socket.on(("message", message), (message) => {
  //       state.luckyNumbers = message.message.randomGeneratedNumbers;
  //     })

  //   } catch (error) {
  //     console.log("error");
  //   }
  // },
};
export const getters = {
  countDownStatus: (state) => state.countDown,
  gameid: (state) => state.gameId,
  diffromgetter: (state) => state.dif,
};
