<template>
  <div class="w-printerSize print:left-0 print:top-0 bg-white">
    <div class="">
      <div
        v-for="(spot, index) in print"
        :key="index"
        class="bg-white space-y-2 p-1 divide-y divide-black"
      >
        <div class="flex justify-between">
          <div>
            <div class="text-text text-lg flex justify-center">
              <img
                class="h-14 w-14 mt-3"
                src="../../public//logo.svg"
                alt=""
                srcset=""
              />
            </div>
            <div class="text-center text-2xl text-black font-extrabold">
              KenoPro
            </div>
          </div>
          <div>
            <div class="flex justify-between mt-2 font-bold">
              <div class="text-sm">
                <!-- <p>ID: {{ spot.customId }}</p> -->
                <p>Game ID: {{ spot.gameId.customId }}</p>
                <p>Branch: {{ spot.placedBranch.branchLocation }}</p>
                <!-- <p>Agent: {{ spot.placedBranch.agentName }}</p> -->
                <p>{{ spot.placedCashier.employeeName }}</p>
                <p>{{ spot.placedTime }}</p>
              </div>
            </div>
          </div>
        </div>
        <div class="container">
          <table class="table-fixed">
            <thead>
              <tr>
                <th class="px-2 border border-black">Spots</th>
                <th class="pl-1 border border-black">Stake</th>
                <th class="pl-1 border border-black">Payout</th>
              </tr>
            </thead>
            <tbody>
              <tr class="">
                <td class="border border-b border-black w-80">
                  <div
                    class="flex space-x-1"
                    v-for="(ss, index) in spot.game"
                    :key="index"
                  >
                    <i
                      style="font-size: xx-small"
                      class="mt-1 fa fa-asterisk"
                    ></i>
                    <div
                      class="flex justify-start divide-y"
                      v-for="(s, index) in ss.selectedNumbers"
                      :key="index"
                    >
                      <div class="text-base mr-1">{{ s.number }},</div>
                    </div>
                  </div>
                </td>
                <td class="border border-b border-black w-20">
                  <div v-for="(s, index) in spot.game" :key="index">
                    <div class="text-base px-1">{{ s.placedMoney }}</div>
                  </div>
                </td>
                <td class="border border-b border-black w-24 px-1">
                  <div v-for="(s, index) in spot.game" :key="index">
                    <div class="text-base">
                      {{ s.possiblePayout.toLocaleString() }}
                    </div>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        <!-- <div class="container">
          <table class="table-fixed">
            <thead>
              <tr>
                <th class="px-2 border border-black">Spots</th>
                <th class="pl-1 border border-black">$Stake</th>
                <th class="pl-1 border border-black">$Payout</th>
              </tr>
            </thead>
            <tbody>
              <tr class="">
                <td class="border w-80 border-black">
                  <div
                    class="flex space-x-1"
                    v-for="(ss, index) in spot.game"
                    :key="index"
                  >
                    <i
                      style="font-size: xx-small"
                      class="mt-1 fa fa-asterisk"
                    ></i>
                    <div
                      class="flex justify-start divide-y"
                      v-for="(s, index) in ss.selectedNumbers"
                      :key="index"
                    >
                      <div class="text-base mr-1">{{ s.number }},</div>
                    </div>
                  </div>
                </td>
                <td class="border border-black w-20">
                  <div v-for="(s, index) in spot.game" :key="index">
                    <div class="text-base px-1">{{ s.placedMoney }}</div>
                  </div>
                </td>
                <td class="border border-black w-20 px-1">
                  <div v-for="(s, index) in spot.game" :key="index">
                    <div class="text-base">
                      {{ s.possiblePayout.toLocaleString() }}
                    </div>
                  </div>
                </td>
                <p class="border-black border"></p>
              </tr>
            </tbody>
          </table>
        </div> -->

        <!-- <div
          class="grid grid-cols-5 font-bold w-full divide-black text-xs divide-y-2"
        >
          <div class="col-span-3">
            <p class="underline text-base font-bold">Spots</p>
            <div
              class="flex space-x-1"
              v-for="(ss, index) in spot.game"
              :key="index"
            >
              <i style="font-size: xx-small" class="mt-1 fa fa-asterisk"></i>
              <div
                class="flex justify-start divide-y"
                v-for="(s, index) in ss.selectedNumbers"
                :key="index"
              >
                <div class="text-base mr-1">{{ s.number }},</div>
              </div>
            </div>
          </div>
          <div class="text-right col-span-1">
            <p class="underline font-bold text-base">$ Stake</p>
            <div v-for="(s, index) in spot.game" :key="index">
              <div class="text-base">{{ s.placedMoney }}</div>
            </div>
          </div>
          <div class="text-right col-span-1">
            <p class="underline font-bold text-base">$ Payout</p>
            <div v-for="(s, index) in spot.game" :key="index">
              <div class="text-base">
                {{ s.possiblePayout.toLocaleString() }}
              </div>
            </div>
          </div>
        </div> -->
        <div class="flex justify-between w-full text-xs">
          <div class="flex space text-base font-bold mt-2">
            <p>Total Stake = {{ spot.totalPlacedMoney }}</p>
          </div>
          <div class="flex">
            <p class="font-bold mt-2 text-base">
              Total Payout = {{ spot.maxPossiblePayout.toLocaleString() }}
            </p>
          </div>
        </div>
        <div class="text-sm text-center">
          <div class="w-full">
            <img
              @load="runInParent"
              class="w-full h-24"
              :src="`${URL}/${spot.barCodePath}`"
              alt=""
            />
          </div>
        </div>
      </div>
    </div>
    <div class="text-sm text-center font-bold">
      powered by Proxima Technologies
    </div>
    <div class="text-xs text-center flex justify-center space-x-2">
      <p>To be an agent contact us</p>

      <p class="font-bold tracking-wide">0944084448</p>
    </div>
    <BaseButton
      @click="PrintPage()"
      class="text-center print:hidden w-full bg-text rounded-md text-white"
      ><i class="fa fa-print"></i>Print</BaseButton
    >
  </div>
</template>

<script>
// const QR_CODE = process.env.VUE_APP_API_BASE_URL;s
const API_URL = process.env.VUE_APP_API_BASE_URL;
import { mapState, mapGetters } from "vuex";
export default {
  data() {
    return {
      URL: [],
    };
  },
  created() {
    this.URL = process.env.VUE_APP_API_BASE_URL;
  },
  //   props: ["item", "bus"],
  computed: {
    returnPrinterSetting() {
      return this.$store.state.cashier.codeSetting;
    },
    ...mapState("cashier", ["game"]),
    ...mapState("cashier", ["totalPrice"]),
    ...mapState("cashier", ["print"]),
    ...mapGetters("modals", ["printerStatus"]),
  },
  mounted() {
    // this.bus.$on("print", this.print);
  },
  methods: {
    modal() {
      this.$store.dispatch("modals/modal", {
        stat: "printer",
        status: !this.printerStatus,
      });
    },
    runInParent() {
      this.$parent.printPage();
    },
    PrintPage() {
      print();
      this.$parent.focusInput();
    },
  },
};
</script>

<style></style>
