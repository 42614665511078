<template>
  <div class="print:hidden bg-darkcard sticky top-0">
    <div class="flex justify-between">
      <div class="px-5 flex justify-start space-x-5 py-3 w-3/5">
        <p
          v-for="(nav, i) in navBar"
          :key="i"
          :class="{
            'text-text': active == nav.to,
          }"
          @click="routerDotPush(nav.to)"
          class="text-gray-300 b text-sm cursor-pointer"
        >
          {{ nav.label }}
        </p>
        <div class="flex space-x-2 text-yellow-500 text-sm">
          <p class="text-green-500">Game Id:{{ currentGameId }}</p>
          <p>{{ gameStatus }}</p>
          <vue-countdown-timer
            class="text-redo"
            :start-time="startingTime"
            :end-time="endingTime"
            :interval="1000"
            :countdown="''"
            :start-label="''"
            :end-label="''"
            label-position=""
            :end-text="'00:00'"
            :day-txt="''"
            :hour-txt="''"
            :minutes-txt="':'"
            :seconds-txt="''"
          >
          </vue-countdown-timer>
        </div>
      </div>
      <div class="w-32 mt-1">
        <BaseButton
          buttonClass="text-center mt-1 w-full text-white bg-red-500 text-xs p-2 px-4 rounded-md"
          @click="openSearchTicketModal()"
          ><i class="fa fa-search fa-1x"></i> Scan Ticket</BaseButton
        >
      </div>
      <div class="flex justify-evenly space-x-6 my-2 mx-5">
        <div class="flex space-x-8">
          <p
            v-if="user.employeeName"
            class="text-gray-300 text-sm cursor-pointer"
          >
            {{ returnUserName }}
          </p>
          <div class="flex justify-center text-xs text-gray-300">
            <p class="cursor-pointer bg-text rounded-md p-1" @click="logout()">
              Logout
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import io from "socket.io-client";

import { mapGetters } from "vuex";
import countDown from "./countDown.vue";
export default {
  components: {
    countDown,
  },
  data() {
    return {
      user: [],
      gameStatus: "",
      startingTime: 0,
      endingTime: 0,
      currentGameId: "",
      dif: 0,
      endAt: 0,
      socket: {},
      activeNavBar: "cashier",
      navBar: [
        {
          label: "Dashboard",
          to: "dashboard",
          Role: ["devTeam", "cashier", "payoutGiver", "agent"],
        },

        {
          label: "Cashier",
          to: "cashier",
          Role: ["devTeam", "cashier", "payoutGiver"],
        },

        {
          label: "Recall History",
          to: "recall-history",
          Role: ["devTeam", "cashier", "payoutGiver"],
        },
        {
          label: "Games",
          to: "games",
          Role: ["devTeam", "cashier", "payoutGiver"],
        },
        {
          label: "Profile Settings",
          to: "config",
          Role: ["devTeam", "cashier", "payoutGiver", "agent"],
        },
      ],
    };
  },
  created() {
    this.socket = io("https://server.proximastech.com");
    this.$store.dispatch("Authentication/getMe").then((response) => {
      if (response) {
        this.user = this.$store.state.Authentication.me;
      }
    });
  },
  mounted() {
    this.socket.on("message", (message) => {
      this.startingTime = message.createdAt;
      this.endingTime = Number(message.message.nextDate);
      this.gameStatus = Number(message.message.status);
      if (
        message.message.status == "newDrawnNumbers" ||
        message.message.status == "existingDrawnNumbers"
      ) {
        this.gameStatus = "Drawing";
        localStorage.setItem("gameIdforCashier", message.message.nextGameId);
        this.currentGameId = message.message.nextGameId;
      } else {
        this.gameStatus = "Ticket on sale";
        localStorage.setItem("gameIdforCashier", message.message.customId);
        this.currentGameId = message.message.customId;
      }
    });
  },
  computed: {
    active() {
      return this.$route.name;
    },
    ...mapGetters("modals", ["searchOpenModalStatus"]),
    returnUserName() {
      let userName = "";
      if (this.$store.state.Authentication.me) {
        userName = this.$store.state.Authentication.me.employeeName;
      }
      return userName;
    },
    openSearchTicketModal() {
      this.$store.dispatch("modals/modal", {
        stat: "searchOpenModal",
        status: !this.searchOpenModalStatus,
      });
    },
  },
  methods: {
    routerDotPush(to) {
      this.activeNavBar = to;
      this.$router.push({ name: to });
    },
    logout() {
      this.socket.disconnect();
      const routeToHome = () => {
        // this.$store.dispatch('modals/sidebar', false)
        localStorage.clear();
        this.$store.dispatch("Authentication/logOut");
        // this.$store.dispatch('users/removingUserInfo')
        this.$router.push({
          path: "/",
        });
      };
      window.setTimeout(routeToHome, 1000);
    },
  },
};
</script>

<style scoped>
.dropdown:hover .dropdown-content {
  display: block;
  right: 0%;
  text-align: left;
}
</style>
