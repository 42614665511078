import Vue from "vue";
import Vuex from "vuex";
import * as cashier from "./modules/cashier";
import * as Authentication from "./modules/Authentication";
import * as notification from "./modules/notification.js";
import * as modals from "./modules/Modals";
import * as keno from "./modules/Keno";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {},
  getters: {},
  mutations: {},
  actions: {},
  modules: {
    cashier,
    Authentication,
    notification,
    modals,
    keno,
  },
});
