<template>
  <div
    v-if="gameData.numbers_That_Won"
    class="w-printerSize divide-y divide-black text-sm text-black print:left-0 print:top-0 bg-white"
  >
    <!-- {{ gameData }} -->
    <div>
      <div class="text-text text-lg flex justify-center">
        <img
          class="h-14 w-14 mt-3"
          src="../../public//logo.svg"
          alt=""
          srcset=""
        />
      </div>
      <div class="text-center text-2xl text-black font-extrabold">KenoPro</div>
    </div>

    <div class="flex justify-between space-x-4">
      <div class="text-center">Game Result of {{ gameData.gameId }}</div>
      <div class="flex justify-center">
        <!-- <div>Game Date</div> -->
        <!-- <div>{{ gameData.matchRegisteredDate }}</div> -->
      </div>
    </div>

    <div>
      <div>Drawn Numbers</div>
      <div class="flex space-x-2 flex-wrap">
        <p v-for="(i, index) in gameData.numbers_That_Won" :key="index">
          {{ i }},
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  data() {
    return {
      // data: [],
    };
  },
  props: {
    gameData: {
      required: true,
    },
  },
  created() {
    // this.data = this.gameData;
    // console.log("Main: ", this.gameData);
  },
  computed: {
    // ...mapState("cashier", ["GameResult"]),
  },
  methods: {},
};
</script>

<style></style>
