<template>
  <div class="w-full p-4 text-white">
    <div class="flex justify-between mx-auto mb-5">
      <p class="text-gray-400">Game Details</p>
      <img
        @click="modalClose()"
        class="cursor-pointer"
        src="../../assets/icons/x.svg"
        alt=""
        srcset=""
      />
    </div>

    <div class="p-8 gap-5 text-center bg-darkbg mt-2 w-full">
      <div class="flex text-center w-full space-x-3">
        <div>Game Id:</div>
        <div>{{ details.gameId }}</div>
      </div>
      <div class="flex text-left space-x-3">
        <div>Created At:</div>
        <div>{{ details.createdAt | date }}</div>
      </div>
      <div class="flex text-left space-x-3">
        <div>Numbers:</div>
        <div class="flex space-x-2 flex-wrap">
          <p v-for="(i, index) in details.numbers_That_Won" :key="index">
            {{ i }},
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  data() {
    return {
      details: [],
    };
  },
  props: {
    dataInfo: {
      required: true,
    },
  },
  created() {
    this.details = this.dataInfo.data.doc;
    this.details.numbers_That_Won.sort((a, b) => a - b);

    // console.log("dataInfo: ", this.dataInfo);
  },
  methods: {
    modalClose() {
      this.$parent.Modal();
    },
  },
};
</script>

<style></style>
