import axios from "axios";
import NProgress from "nprogress";
import "nprogress/nprogress.css";
import CryptoJS from "crypto-js";
const key = "loveIsAGameForFool";
const API_URL = process.env.VUE_APP_API_BASE_URL;
// function res() {
//   const encryptedToken = localStorage.getItem("encryptedCashierToken");
//   if (encryptedToken) {
//     const decryptedBytes = CryptoJS.AES.decrypt(encryptedToken, key);
//     const tk_value = decryptedBytes.toString(CryptoJS.enc.Utf8);
//     // let tk_value = localStorage.getItem("encryptedCashierToken");
//     let response = axios.create({
//       baseURL: `${API_URL}/api/v1`,
//       // baseURL: `${API_URL}:7072/api/v1`,
//       timeout: 30000,
//       timeoutErrorMessage: "timeout",
//       headers: {
//         authorization: "Bearer " + tk_value,
//       },
//     });
//     response.interceptors.request.use((config) => {
//       NProgress.start();
//       return config;
//     });
//     response.interceptors.response.use((config) => {
//       NProgress.done();
//       return config;
//     });
//     // console.log("response from axios: ", response);
//     return response;
//   } else {
//     const alert = {
//       type: "error",
//       message: "Invalid token login again!",
//     };
//     store.dispatch("notification/add", alert);
//     localStorage.clear();
//     router.push("/");
//   }
// }
function res() {
  // let tk_value = localStorage.getItem("token");
  const encryptedToken = localStorage.getItem("encryptedCashierToken");
  const decryptedBytes = CryptoJS.AES.decrypt(encryptedToken, key);
  const tk_value = decryptedBytes.toString(CryptoJS.enc.Utf8);
  const instance = axios.create({
    baseURL: `${API_URL}/api/v1`,
    timeout: 20000,
    headers: {
      authorization: "Bearer " + tk_value,
    },
  });
  instance.interceptors.request.use((config) => {
    NProgress.start();
    return config;
  });
  instance.interceptors.response.use(
    (response) => {
      NProgress.done();
      return response;
    },
    (error) => {
      NProgress.done();
      return Promise.reject(error);
    }
  );

  // console.log("response from axios: ", response);
  return instance;
}

export default {
  getAllTickets(perPage, page) {
    return res().get(
      `/tickets/cashier/todayTicketsHistory?sort=-createdAt&limit=${perPage}&page=${page}`
    );
  },
  getCountAllTickets() {
    return res().get("/tickets/cashier/todayTicketsHistoryCount");
  },
  searchGame(search) {
    return res().get(`/gameResults/cashier/search?q=${search}`);
  },
  // getLuckyNumbers(search) {
  //   return res().get(`/gameResult/cashier/search?q=${search}`);
  // },
  getAllGames(perPage, page) {
    return res().get(
      `/games/todayGames?sort=-createdAt&limit=${perPage}&page=${page}`
    );
  },
  createTicket(payload) {
    // console.log("ppppp :", payload);
    return res().post("/tickets/cashier", payload);
  },
  createTicketAgain(id) {
    // console.log("ppppp :", payload);
    return res().post(`/tickets/duplicateTicket/${id}`);
  },
  EditTicketFromCreated(id) {
    // console.log("ppppp :", payload);
    return res().get(`/tickets/cashier/${id}`);
  },
  sendForDevTeam(payload) {
    return res().post("/comments", { comment: payload });
  },
  getMe() {
    return res().get("/branchEmployees/profile/");
  },
  getAllTimeReport() {
    return res().get("/branchEmployees/statusData/");
  },
  changeStatus(id) {
    // console.log("id: ", id);
    return res().patch(`/tickets/updateTookMoney/${id}`);
  },
  searchTicket(search) {
    // console.log("search: ", search);
    return res().get(`/tickets/cashier/search?q=${search}`);
  },
  searchTicketUsingBarCode(search) {
    // console.log("search: ", search);
    return res().get(`/tickets/cashier/search?q=${search}`);
  },
  todayReport() {
    return res().get("/tickets/todayReport/");
  },
  updateMyProfile(payload) {
    return res().patch("/branchEmployees/updateProfile/", payload);
  },
  updateMyPassword(payload) {
    // console.log("log", payload);
    return res().patch("/branchEmployees/updateMyPassword/", payload);
  },
  RejectBet(id) {
    // console.log("log", payload);
    return res().delete(`/tickets/cancelTicket/${id}`);
  },
};
