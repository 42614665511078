/* eslint-disable */
import AxiosService from "../../services/AxiosServices";
import router from "@/router";
import ErrorHandler from "../../services/ErrorHandler";
import error from "./error";
// import { Socket } from "socket.io";

export const namespaced = true;
export const state = {
  printer_off: true,
  fetchTicketsNow: false,
  allTickets: [],
  countTickets: 0,
  newEditTicket: [],
  codeSetting: "",
  report: [],
  tickets: [],
  barCode: [],
  allTimeReport: [],
  print: [],
  ticket: {
    game: [],
  },
  singleTicket: [],
  searchResult: [],
  totalPrice: 0,
};
export const mutations = {
  SET_CREATED_TICKET(state, payload) {
    state.print = payload;
  },
};

export const actions = {
  startTime({ state }, startTime) {
    if (startTime) {
      state.start_time = startTime;
      return true;
    }
  },
  endTime({ state }, endTime) {
    if (endTime) {
      state.end_time = endTime;
      return true;
    }
  },
  clearPrint({ state }) {
    // console.log("clear print");
    state.print = [];
    return true;
  },
  addGame({ state }, payload) {
    if (payload) {
      payload.selectedNumbers.sort((a, b) => a.number - b.number);
      state.ticket.game.push(payload);
      state.totalPrice = state.ticket.game.reduce(function (sum, price) {
        return sum + price.placedMoney;
      }, 0);
      return true;
    }
  },
  async addGameAfterEdit({ state }, payload) {
    try {
      const { selectedNumbers, placedMoney, index } = payload;
      state.ticket.game[index].placedMoney = Number(placedMoney);
      await selectedNumbers.sort((a, b) => a.number - b.number);
      state.ticket.game[index].selectedNumbers = selectedNumbers;
      state.totalPrice = state.ticket.game.reduce(function (sum, price) {
        return sum + price.placedMoney;
      }, 0);
      return true;
    } catch (error) {
      console.log("error adding ");
    }
  },
  async EditCash({ state }, { index, newPrice }) {
    try {
      let Tick = state.ticket.game;
      Tick[index].placedMoney = Number(newPrice);
      state.totalPrice = state.ticket.game.reduce(function (sum, price) {
        return sum + price.placedMoney;
      }, 0);
      return true;
    } catch (error) {
      return false;
      console.log("error");
    }
  },
  async editCash({ state }, { index, newPrice }) {
    let value = [];
    try {
      let Tick = state.ticket.game;
      value = Tick[index];
      state.totalPrice = state.ticket.game.reduce(function (sum, price) {
        return sum + price.placedMoney;
      }, 0);
      return value;
    } catch (error) {
      console.log("error");
      return false;
    }
  },
  async RemoveBet({ state }, index) {
    if (index >= 0) {
      // console.log("here: ", index);
      state.ticket.game.splice(index, 1);
      state.totalPrice = state.ticket.game.reduce(function (sum, price) {
        return sum + price.placedMoney;
      }, 0);
      return true;
    }
  },
  RemoveAllBets({ state }) {
    state.ticket.game = [];
    state.totalPrice = state.ticket.game.reduce(function (sum, price) {
      return sum + price.placedMoney;
    }, 0);
  },
  cashCalculator({ state }) {
    state.totalPrice = state.ticket.game.reduce(function (sum, price) {
      return sum + price.placedMoney;
    }, 0);
  },
  getCountAllTickets({ state, dispatch }) {
    return AxiosService.getCountAllTickets().then((res) => {
      state.countTickets = res.data.result;
      // console.log("Tickets: ", res.data.result);
    });
    // .catch((error) => {
    //   ErrorHandler(error);
    //   return false;
    // });
  },
  getAllTickets({ state, dispatch }, { perPage, page }) {
    return AxiosService.getAllTickets(perPage, page)
      .then((res) => {
        state.allTickets = [];
        state.allTickets = res.data.doc;
        // console.log("Tickets: ", res.data.doc);
        return res.data.doc;
      })
      .catch((error) => {
        console.log("error: ", error);
        ErrorHandler(error);
        // return false;
      });
  },
  getAllGames({ state, dispatch }, { perPage, page }) {
    return AxiosService.getAllGames(perPage, page)
      .then((res) => {
        // console.log("response from get all games", res.data.doc);
        // console.log("Tickets: ", res.data.doc);
        return res.data.doc;
      })
      .catch((error) => {
        ErrorHandler(error);
        // return false;
      });
  },
  getAllTicketsCashierDashboard({ state, dispatch }, { perPage, page }) {
    return AxiosService.getAllTickets(perPage, page)
      .then((res) => {
        // state.allTickets = [];
        // state.allTickets = res.data.doc;
        // console.log("Tickets: ", res.data.doc);
        const ticketsData = res.data.doc;
        // const data = [];
        // for (let index = 0; index < 5; index++) {
        //   data.push(ticketsData[index]);
        // }
        // console.log("res: ", res);
        return ticketsData;
      })
      .catch((error) => {
        ErrorHandler(error);
        // return false;
      });
  },
  getAllTimeReport({ state, dispatch }) {
    return AxiosService.getAllTimeReport()
      .then((res) => {
        state.allTimeReport = res.data;
        // console.log("Tickets: ", res.data);
        return res;
      })
      .catch((error) => {
        return false;
      });
  },
  getTodayReports({ state, dispatch }) {
    return AxiosService.todayReport()
      .then((res) => {
        state.report = res.data;
        // console.log("Tickets: ", res.data);
        return res;
      })
      .catch((error) => {
        ErrorHandler(error);
        return false;
      });
  },
  RejectBet({ dispatch }, id) {
    return AxiosService.RejectBet(id)
      .then((res) => {
        // state.report = res.data;
        // console.log("Tickets: ", res.data);
        return res;
      })
      .catch((error) => {
        ErrorHandler(error);
        return false;
      });
  },
  getTicketById({ state, dispatch }, id) {
    AxiosService.getTicketById(id)
      .then((res) => {
        state.singleTicket = res.data;
      })
      .catch((error) => {
        ErrorHandler(error);
        return false;
      });
  },
  searchTicket({ state, dispatch }, search) {
    return AxiosService.searchTicket(search)
      .then((res) => {
        state.searchResult = res.data.doc;
        // console.log("search", res.data.doc);
        return res.data.doc;
      })
      .catch((error) => {
        ErrorHandler(error);
        return false;
      });
  },
  searchGameTicketRecall({ state, dispatch }, search) {
    return AxiosService.searchGame(search).then((res) => {
      return res;
    });
  },
  searchGame({ state, dispatch }, search) {
    return AxiosService.searchGame(search)
      .then((res) => {
        return res;
      })
      .catch((error) => {
        ErrorHandler(error);
        return false;
      });
  },
  searchTicketUsingBarCode({ state, dispatch }, search) {
    state.barCode = [];
    return AxiosService.searchTicketUsingBarCode(search)
      .then((res) => {
        // console.log("there number 2");
        state.barCode = res.data.doc[0];
        // console.log("search", res.data.doc);
        // console.log("search", res.data);
        return res.data.doc;
      })
      .catch((error) => {
        ErrorHandler(error);
        return false;
      });
  },
  searchTicketUsingBarCodeInDashboard({ state, dispatch }, search) {
    state.barCode = [];
    return AxiosService.searchTicketUsingBarCode(search)
      .then((res) => {
        if (res.data.doc[0]) {
          // console.log("search", res.data.doc[0].customId);
          let id = res.data.doc[0].customId;
          dispatch("EditTicketFromCreated", id);
        } else {
          return false;
        }
        // state.barCode = res.data.doc[0];

        // console.log("search", res.data);
        return res.data.doc;
      })
      .catch((error) => {
        ErrorHandler(error);
        return false;
      });
  },
  changeStatus({ state, dispatch }, id) {
    let backData = [];
    return AxiosService.changeStatus(id)
      .then((res) => {
        return AxiosService.searchTicketUsingBarCode(id).then((response) => {
          backData = response.data.doc;
          const notification = {
            type: "success",
            message: "Payout Given Successful!",
          };
          dispatch("notification/add", notification, { root: true });
          return response.data.doc;
        });
      })
      .catch((error) => {
        ErrorHandler(error);
        return false;
      });
  },
  getAllTicketsByDate({ state, dispatch }, date) {
    AxiosService.getAllTicketsByDate(date)
      .then((res) => {
        state.tickets = res.data;
      })
      .catch((error) => {
        ErrorHandler(error);
        return false;
      });
  },
  // data =[
  //   {
  //     new: 1,
  //     numbers: [
  //       {number:28},
  //       {number:12},
  //       {number:30},
  //     ]
  //   },
  //   {
  //     new: 8,
  //     numbers: [
  //       {number:36},
  //       {number:99},
  //       {number:3},
  //     ]
  //   },
  //   {
  //     new: 1,
  //     numbers: [
  //       {number:9},
  //       {number:78},
  //       {number:30},
  //     ]
  //   },
  // ]
  // Post Request
  createTicket({ state, commit, dispatch }, gameId) {
    return AxiosService.createTicket({
      gameId: gameId,
      game: state.ticket.game,
    })
      .then((res) => {
        state.print = [];
        state.tickets = [];
        state.ticket.game = [];
        state.totalPrice = 0;
        commit("SET_CREATED_TICKET", [res.data.doc]);
        const notification = {
          type: "success",
          message: "Ticket created successfully!",
        };
        dispatch("notification/add", notification, { root: true });
        return true;
      })
      .catch((error) => {
        ErrorHandler(error);
        return false;
      });
  },
  createTicketAgain({ state, commit, dispatch }, id) {
    return AxiosService.createTicketAgain(id).then((res) => {
      state.print = [];
      state.tickets = [];
      state.ticket.game = [];
      state.totalPrice = 0;
      commit("SET_CREATED_TICKET", [res.data.doc]);
      const notification = {
        type: "success",
        message: "Ticket created successfully!",
      };
      dispatch("notification/add", notification, { root: true });
      return true;
    });
  },
  EditTicketFromCreated({ state, commit, context, dispatch }, id) {
    state.ticket.game = [];
    // console.log("ho ho", id);
    return AxiosService.searchTicketUsingBarCode(id).then((res) => {
      const games = res.data.doc[0].game;
      state.ticket.game = games;
      state.totalPrice = state.ticket.game.reduce(function (sum, price) {
        return sum + price.placedMoney;
      }, 0);
      return true;
    });
  },
};
export const getters = {};
